import { MenuItem, TextField } from '@common/material'
import { Box, Flex } from '@fundamentals'
import { AdvancedFilterPopover } from './AdvancedFilterPopover'
import {
  advancedInspectionFilterOptionOperators,
  AdvancedInspectionFilterValue,
} from '@core/providers/filters'
import { localizedStrings } from '@core/strings'
import { useEffect } from 'react'

type AdvancedInspectionFilterProps = {
  onChange: ({ value, operator }: AdvancedInspectionFilterValue) => void
  storeValue: AdvancedInspectionFilterValue
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const AdvancedInspectionFilter: React.FC<
  AdvancedInspectionFilterProps
> = ({ onChange, storeValue, anchorEl, setAnchorEl }) => {
  const value = storeValue?.value
  const operator =
    storeValue?.operator ?? advancedInspectionFilterOptionOperators[0].value

  const isDueInLessThan = operator === 'IS_DUE_IN_LESS_THAN'

  useEffect(() => {
    onChange({ value, operator })
  }, [])

  return (
    <AdvancedFilterPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Flex flexDirection='column' p={2} pr={1}>
        <TextField
          value={operator}
          select
          size='small'
          label='Select filter operator'
          sx={{
            pr: 1,
            mb: isDueInLessThan && 2,
            minWidth: 200,
          }}
          onChange={(e) =>
            onChange({
              value: null,
              operator: e.target
                .value as AdvancedInspectionFilterValue['operator'],
            })
          }
        >
          {advancedInspectionFilterOptionOperators.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {isDueInLessThan && (
          <Box minWidth={350}>
            <TextField
              value={value}
              onChange={(e) => {
                const value = parseInt(e.target.value)
                onChange({ value, operator })
              }}
              placeholder={localizedStrings.numberOfDays}
              fullWidth
              type='number'
              inputProps={{
                step: 'any',
                'data-test': 'request-numeric-input',
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Box>
        )}
      </Flex>
    </AdvancedFilterPopover>
  )
}
