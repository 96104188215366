import React, { useCallback, useMemo } from 'react'
import { TimelineBlockBase } from '@common/Timeline'
import { Project } from '@core/entities/project'
import { localizedStrings } from '@core/strings'
import { Flex, Text } from '@fundamentals'
import EastIcon from '@mui/icons-material/East'
import { Chip } from '@common/material'

type ComponentProps = {
  projectDataAfter: Project
  projectDataBefore: Project
}

export const TimelineProjectUpdatesBlock: React.FC<ComponentProps> = ({
  projectDataAfter,
  projectDataBefore,
}) => {
  const getUpdatedProperties = useCallback(
    (projectDataBefore: Project, projectDataAfter: Project) => {
      const updatedProperties = []

      for (const key in projectDataBefore) {
        if (key === 'isTest' || key === 'isKey') continue
        if (key === 'address') {
          const addressBefore = projectDataBefore[key]
          const addressAfter = projectDataAfter[key]

          for (const addressKey in addressBefore) {
            if (addressBefore[addressKey] !== addressAfter[addressKey]) {
              updatedProperties.push({
                name: `address ${addressKey}`,
                preValue: addressBefore[addressKey],
                postValue: addressAfter[addressKey],
              })
            }
          }
        } else if (projectDataBefore[key] !== projectDataAfter[key]) {
          updatedProperties.push({
            name: key,
            preValue: projectDataBefore[key],
            postValue: projectDataAfter[key],
          })
        }
      }

      return updatedProperties
    },
    [],
  )

  const updatedProperties = useMemo(
    () => getUpdatedProperties(projectDataBefore, projectDataAfter),
    [projectDataBefore, projectDataAfter, getUpdatedProperties],
  )

  return (
    <TimelineBlockBase title={localizedStrings.projectUpdates}>
      {updatedProperties.map((property) => (
        <Flex key={property.name} mt={0.5} alignItems='center'>
          <Text variant='body2' mr={1}>
            {property.name}:
          </Text>
          {property.name === 'status' ? (
            <Chip
              size='small'
              color={Project.statusColour(property.preValue)}
              label={Project.statusText(property.preValue)}
            />
          ) : (
            <Text variant='body2' color='text.secondary'>
              {property.preValue}
            </Text>
          )}
          <EastIcon sx={{ mx: 1, opacity: 0.5 }} fontSize='small' />
          {property.name === 'status' ? (
            <Chip
              size='small'
              color={Project.statusColour(property.postValue)}
              label={Project.statusText(property.postValue)}
            />
          ) : (
            <Text variant='body2' fontWeight='400'>
              {property.postValue}
            </Text>
          )}
        </Flex>
      ))}
    </TimelineBlockBase>
  )
}
