import { useRef, useState } from 'react'
import { MapRef, Layer, Source, Marker } from 'react-map-gl'
import { bboxPolygon, point } from '@turf/turf'
import { Feature, Point, Polygon } from 'geojson'
import { useQueryClient } from '@tanstack/react-query'
import { ModalDialogContainer } from '@common/modal'
import { useGetW3WToCoords, w3wKeys } from '@core/react-query/features/w3w'
import { useModal } from '@common/GlobalModal'
import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import { TextField, Button } from '@common/material'
import { Box, Flex, Image } from '@fundamentals'
import { Map } from '@elements'
import { What3wordsAutosuggest } from '@what3words/react-components'
import { localizedStrings } from '@core/strings'
import ClearIcon from '@mui/icons-material/Clear'

type W3WMapModalProps = {
  projectId: number
  initialWhat3Words: string
  onChangeWhat3Words: (what3words: string) => void
}

const what3WordsRegex =
  /^\/{0,}[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}$/

export const W3WMapModal: React.FC<W3WMapModalProps> = ({
  projectId,
  initialWhat3Words,
  onChangeWhat3Words,
}) => {
  const mapRef = useRef<MapRef>()
  const w3wAutoSuggestRef = useRef<HTMLWhat3wordsAutosuggestElement>(null)
  const queryClient = useQueryClient()
  const { hideModal } = useModal()
  const [what3words, setWhat3Words] = useState<string>(initialWhat3Words)
  const [gridData, setGridData] = useState(null)
  const [marker, setMarker] = useState<Feature<Point>>(null)
  const [highlightedGridCellUnderMarker, setHighlightedGridCellUnderMarker] =
    useState<Feature<Polygon>>(null)

  const getW3WToCoordsHook = useGetW3WToCoords(
    { what3Words: what3words },
    {
      enabled: !!what3words,
      onSuccess: (data) => {
        const markerPoint = data?.features?.[0]

        setMarker(markerPoint)
        setHighlightedGridCellUnderMarker(bboxPolygon(markerPoint.bbox))

        mapRef?.current?.flyTo({
          center: [
            markerPoint.geometry.coordinates[0],
            markerPoint.geometry.coordinates[1],
          ],
          zoom: 18,
          duration: 1000,
        })
      },
      onError: (error: any) => {
        setMarker(null)
      },
    },
  )

  const onLoad = (event) => {
    if (mapRef?.current?.getZoom() > 17) {
      drawGrid() // Load grid data on map load if zoom is sufficient
    }
  }

  const drawGrid = async () => {
    const zoom = mapRef?.current?.getZoom()
    const loadFeatures = zoom > 17

    if (loadFeatures) {
      const bounds = mapRef?.current?.getBounds()
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const w3wBounds = {
        southwest: { lat: sw.lat, lng: sw.lng },
        northeast: { lat: ne.lat, lng: ne.lng },
      }

      try {
        const response = await queryClient.fetchQuery(
          w3wKeys.getW3WGridSection({ w3wBounds }),
        )

        setGridData(response)
      } catch (error) {
        console.error(error)
      }
    } else {
      /*
       * Clear grid data and highlighted grid cell if zoom level is below threshold
       */
      setGridData(null)
      setHighlightedGridCellUnderMarker(null)
    }
  }

  const handleOnMapClick = async (event: mapboxgl.MapLayerMouseEvent) => {
    const { lng, lat } = event.lngLat

    const featurePointClicked = point([lng, lat])

    const response = await queryClient.fetchQuery(
      w3wKeys.getCoordsToW3W({ point: featurePointClicked }),
    )

    if (response) {
      const markerPoint = response?.features?.[0]

      setMarker(markerPoint)
      setHighlightedGridCellUnderMarker(bboxPolygon(markerPoint.bbox))
      setWhat3Words(markerPoint.properties.words)
    }
  }

  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '90%',
        },
      }}
    >
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.pointOfWorks}
        <IconButton
          aria-label='close-modal'
          onClick={() => {
            hideModal()
          }}
        >
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex alignItems='center' justifyContent='space-between' p={1}>
          <Box>
            <What3wordsAutosuggest
              ref={w3wAutoSuggestRef}
              api_key={Project.w3wKey}
              onSelected_suggestion={(event) => {
                setWhat3Words(event.detail.suggestion.words)
              }}
            >
              <TextField
                value={what3words}
                onChange={(e) => {
                  setWhat3Words(e.target.value)
                }}
                placeholder={localizedStrings.pointOfWorks3WordsPlaceholder}
                label={localizedStrings.what3Words}
                InputLabelProps={{ shrink: true }}
                sx={{ width: 400 }}
                fullWidth
              />
            </What3wordsAutosuggest>
          </Box>

          <Button
            disabled={!what3words || !what3WordsRegex.test(what3words)}
            variant='contained'
            color='primary'
            size='large'
            sx={{ width: 200 }}
            onClick={() => {
              hideModal()
              onChangeWhat3Words(what3words)
            }}
          >
            {localizedStrings.confirm}
          </Button>
        </Flex>

        <Flex flex={1} mt={2}>
          <Map
            mapRef={mapRef}
            projectId={projectId}
            onLoad={onLoad}
            onMoveEnd={drawGrid}
            onClick={handleOnMapClick}
            style={{ height: '70vh' }}
          >
            {gridData && (
              <Source id='grid' type='geojson' data={gridData}>
                <Layer
                  id='grid-layer'
                  type='line'
                  layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                  }}
                  paint={{
                    'line-color': 'orange',
                    'line-width': 1,
                  }}
                />
              </Source>
            )}

            {highlightedGridCellUnderMarker && (
              <Source
                id='highlighted-grid'
                type='geojson'
                data={highlightedGridCellUnderMarker}
              >
                <Layer
                  id='highlighted-grid-layer'
                  type='fill'
                  paint={{
                    'fill-color': '#FF0000',
                    'fill-opacity': 0.5,
                  }}
                />
              </Source>
            )}

            {marker && (
              <Marker
                anchor='bottom'
                longitude={marker.geometry.coordinates[0]}
                latitude={marker.geometry.coordinates[1]}
              >
                <Image
                  alt='point-marker'
                  src='/images/icons/markers/permit_marker_red.svg'
                />
              </Marker>
            )}
          </Map>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}
