import React from 'react'
import { Flex, Text } from '@fundamentals'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'
import { SiteBoxDocument } from '@core/entities/SiteBoxDocument'
import { localizedStrings } from '@core/strings'

type ComponentProps = {
  preSiteBoxDocumentValuesData: Partial<SiteBoxDocument>
  postSiteBoxDocumentValuesData: Partial<SiteBoxDocument>
}

export const TimelineSiteBoxDocumentUpdateBlock: React.FC<ComponentProps> = ({
  preSiteBoxDocumentValuesData,
  postSiteBoxDocumentValuesData,
}) => {
  return (
    <TimelineBlockBase title={localizedStrings.siteBoxDocumentRenamed}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Text variant='body2'>{preSiteBoxDocumentValuesData.name}</Text>
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Text variant='body2'>{postSiteBoxDocumentValuesData.name}</Text>
      </Flex>
    </TimelineBlockBase>
  )
}
