import { FeatureCollection, MultiLineString } from 'geojson'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { w3wKeys, RequestTypes } from '@core/react-query/features/w3w'

export const useGetW3WGridSection = (
  payload: RequestTypes['getW3WGridSection'],
  options?: UseQueryOptions<FeatureCollection<MultiLineString>, string>,
) => {
  return useQuery({
    ...w3wKeys.getW3WGridSection(payload),
    retry: 0,
    ...options,
  })
}
