import axios from 'axios'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { FeatureCollection, MultiLineString, Point } from 'geojson'

export const w3wKeys = createQueryKeys('w3w', {
  getW3WGridSection: (payload: RequestTypes['getW3WGridSection']) => {
    const queryString = createQueryKeyString({
      'bounding-box': `${payload?.w3wBounds?.southwest?.lat},${payload?.w3wBounds?.southwest?.lng},${payload?.w3wBounds?.northeast?.lat},${payload?.w3wBounds?.northeast?.lng}`,
      format: 'geoJson',
      key: Project.w3wKey,
    })

    return {
      queryKey: [
        payload?.w3wBounds?.southwest?.lat,
        payload?.w3wBounds?.southwest?.lng,
        payload?.w3wBounds?.northeast?.lat,
        payload?.w3wBounds?.northeast?.lng,
      ],
      queryFn: async () => {
        const response = await axios.get(
          `https://api.what3words.com/v3/grid-section${queryString}`,
        )

        return response.data as FeatureCollection<MultiLineString>
      },
    }
  },

  getCoordsToW3W: (payload: RequestTypes['getCoordsToW3W']) => {
    const queryString = createQueryKeyString({
      coordinates: `${payload?.point?.geometry?.coordinates?.[1]},${payload?.point?.geometry?.coordinates?.[0]}`,
      format: 'geoJson',
      language: 'en',
      key: Project.w3wKey,
    })

    return {
      queryKey: [
        payload?.point?.geometry?.coordinates?.[1],
        payload?.point?.geometry?.coordinates?.[0],
      ],
      queryFn: async () => {
        const response = await axios.get(
          `https://api.what3words.com/v3/convert-to-3wa${queryString}`,
        )

        return response.data as FeatureCollection<Point>
      },
    }
  },

  getW3WToCoords: (payload: RequestTypes['getW3WToCoords']) => {
    const queryString = createQueryKeyString({
      words: payload?.what3Words,
      format: 'geoJson',
      key: Project.w3wKey,
    })

    return {
      queryKey: [payload?.what3Words],
      queryFn: async () => {
        const response = await axios.get(
          `https://api.what3words.com/v3/convert-to-coordinates${queryString}`,
        )

        return response.data as FeatureCollection<Point>
      },
    }
  },
})
