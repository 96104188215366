import React, { useMemo } from 'react'
import { TextField } from '@common/material'
import { Autocomplete } from '@common/material/Autocomplete'
import { Box, Text } from '@fundamentals'
import { Section } from '@common/Section'
import { useProjectUsers } from '@core/react-query/features/projects/project/hooks/useProjectUsers'
import { AutocompleteProps } from '@mui/material'
import { localizedStrings } from '@core/strings'
import { ProjectUser } from '@core/entities/user'
import { useGetProfile } from '@core/react-query/features/profile'
import { FieldValues, UseFormSetValue } from 'react-hook-form'

type ProjectUserProps<
  Multiple extends boolean,
  DisabledClearable extends boolean,
> = Partial<AutocompleteProps<number, Multiple, DisabledClearable, false>> & {
  projectId: number
  permitHolder: ProjectUser
  setPermitHolder?: UseFormSetValue<FieldValues>
  onChange?: (userId: number | Array<number>) => void
  readOnly?: boolean
  error?: boolean
  multiple?: Multiple
  disabledClearable?: DisabledClearable
  helperText?: string
  allowSelf?: boolean
}

const ProjectUserPicker: <
  Multiple extends boolean,
  DisabledClearable extends boolean,
>(
  props: ProjectUserProps<Multiple, DisabledClearable>,
) => JSX.Element = ({
  projectId,
  permitHolder,
  setPermitHolder,
  readOnly,
  onChange,
  value,
  error,
  helperText,
  multiple,
  allowSelf,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const { data: projectUsers } = useProjectUsers(
    { projectId, term: searchTerm, memberStatus: 'ACTIVE' },
    { keepPreviousData: true, refetchOnMount: false },
  )
  const { data: profile } = useGetProfile()

  const options = useMemo(() => {
    const projectUsersMap = new Map<number, ProjectUser>()

    if (permitHolder) projectUsersMap.set(permitHolder.id, permitHolder)

    return (
      projectUsers?.pages?.[0]?.content.reduce((acc, user) => {
        if (!allowSelf && profile?.id && profile?.id === user.id) {
          return acc
        }

        acc.set(user.id, user)

        return acc
      }, projectUsersMap) || projectUsersMap
    )
  }, [projectUsers])

  if (readOnly) {
    let projectUser = projectUsers?.pages?.[0]?.content.find(
      (user) => user.id === value,
    )
    if (!projectUser && permitHolder?.id === value) {
      projectUser = permitHolder
    }

    return (
      <Section sx={props?.sx} label={localizedStrings.assignment}>
        <Text>{projectUser?.getFullName()}</Text>
        <Text color='grey'>{projectUser?.email}</Text>
      </Section>
    )
  }

  return (
    <Autocomplete
      value={value}
      options={Array.from(options.keys())}
      getOptionLabel={(option) => options.get(option)?.getFullName()}
      renderOption={(props, optionValue) => {
        const option = options.get(optionValue)
        return (
          <li {...props}>
            <Box>
              <Text>{option?.getFullName()}</Text>
              <Text fontSize={15} color='grey'>
                {option?.email}
              </Text>
            </Box>
          </li>
        )
      }}
      onInputChange={(_, newInputValue, inputChangeReason) => {
        if (inputChangeReason === 'input') setSearchTerm(newInputValue)
      }}
      onChange={(_, newValue) => {
        if (!multiple) {
          setPermitHolder('permitHolder', options.get(newValue as number))
        }

        onChange(newValue as number[])
      }}
      renderInput={(params) => {
        return (
          <TextField
            label={localizedStrings.assignTo}
            defaultValue={value}
            data-test='select-project-dropdown-btn'
            error={error}
            helperText={helperText}
            FormHelperTextProps={{ error, sx: { pt: 0.5, pb: 2 } }}
            required
            {...params}
          />
        )
      }}
      disableClearable
      {...props}
    />
  )
}

export { ProjectUserPicker }
