import { default as legacyTheme } from '../styles/theme'
import { Components, Theme } from '@mui/material/styles'
import { Palette } from '../styles/color-palette-siteassist'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { alpha } from '@mui/material/styles'

type ThemeTemplate = {
  typography: TypographyOptions
  palette: Theme['palette']
  components: Components
}

export type ThemeColors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

const theme: ThemeTemplate = {
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    // but when the automatically created ones are not specified, can mobile know/use it?
    primary: {
      main: Palette.Blue[700],
      light: '#4791DB',
      dark: '#115293',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: Palette.DeepPurple[400],
      light: '#8376CC',
      dark: '#463A86',
      contrastText: '#FFFFFF',
    },
    success: {
      main: Palette.Green[400],
      light: '#5EC297',
      dark: '#257D58',
      contrastText: '#FFFFFF',
    },
    info: {
      main: Palette.Cyan[500],
      light: '#33C6E0',
      dark: '#008097',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: Palette.Amber[700],
      light: '#FFBB33',
      dark: '#B27700',
      contrastText: '#FFFFFF',
    },
    error: {
      main: Palette.Red[500],
      light: '#FF7759',
      dark: '#B23C21',
      contrastText: '#FFFFFF',
    },
    legacy: legacyTheme.palette,
  },
  components: {
    Sidebar: {
      defaultProps: {
        sidebarWidth: 210,
        collapsedSidebarWidth: 90,
        primarySidebarColor: 'common.white',
        nestedSidebarColor: 'common.white',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:focus': {
            outline: 'none',
          },
        },
        containedPrimary: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          textTransform: 'none',
        },
        textPrimary: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          lineHeight: '1rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          boxShadow: `inset 0 -1px ${alpha('#000', 0.12)}`,
          marginBottom: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: '#FF5630' },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // Use existing space / prevents shifting content below field
          marginTop: 0,
          height: 0,
        },
      },
    },
  },
}

export { theme }
