import React from 'react'
import { Image } from '@fundamentals'
import { SxProps } from '@mui/material/styles'

type OrganisationLogoProps = {
  url?: string
  sx?: SxProps
}

export const OrganisationLogo: React.FC<OrganisationLogoProps> = ({
  url,
  sx,
}) => {
  return (
    <Image
      alt='logo'
      sx={{ height: 50, opacity: 1, borderRadius: 1, ...sx }}
      src={url || '/images/placeholders/organisation-placeholder.svg'}
    />
  )
}
