import { Box, Flex, Image } from '@fundamentals'
import React, { useMemo, useRef, useState } from 'react'
import { MapProps, MapRefType } from '@common/material/MapBox'
import { Marker, NavigationControl } from 'react-map-gl'
import { DialogContent } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { ModalDialogContainer } from '@common/modal'
import { Feature, FeatureCollection, Geometry, Point, Polygon } from 'geojson'
import { Map } from '@elements'
import { GeoPolygon } from '@elements/Geolocation'
import { calculateMapBounds, calculateMarkerOffset } from '@modules/geolocation'
import { TimelineCardLegacy } from '@elements/TimelineCardLegacy'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { TimelineItem } from '@common/Timeline/TimelineItem'

export type TimelineMapModalProps = {
  projectId: number
  featurePoint?: Feature<Point>
  featurePolygon?: FeatureCollection<Polygon>
  timeline: Array<AuditResult>
} & MapProps

export const TimelineMapModal: React.FC<TimelineMapModalProps> = ({
  projectId,
  featurePoint,
  featurePolygon,
  timeline,
}) => {
  const { flags } = useFeatureFlags(['timeline_redesign'])
  const mapRef: MapRefType = useRef()
  const [hoveredState, setHoveredState] = useState<{
    id: number | string
    source: 'list' | 'map'
  }>()

  const timelineEntriesWithGeolocation = useMemo(() => {
    return timeline.filter((t) => !!t.getCoordinates())
  }, [timeline])

  const timelineFeaturePoints = useMemo(() => {
    const getMarkerOffset = calculateMarkerOffset() // Initialize offset calculator with internal state

    return timelineEntriesWithGeolocation.map((timelineEntry) => {
      const featurePoint = timelineEntry.getCoordinates()
      featurePoint.id = timelineEntry.id

      // Calculate and assign offset directly to properties
      featurePoint.properties = featurePoint.properties || {}
      featurePoint.properties.offset = getMarkerOffset(featurePoint)

      return featurePoint
    })
  }, [timelineEntriesWithGeolocation])

  const initialViewState = useMemo(() => {
    const features: Array<Feature<Geometry>> = []
    if (featurePoint) features.push(featurePoint)
    if (featurePolygon) {
      if ('features' in featurePolygon) {
        features.push(...featurePolygon.features)
      }
    }
    if (timelineFeaturePoints) {
      features.push(...timelineFeaturePoints)
    }

    return { bounds: calculateMapBounds(features) }
  }, [featurePoint, featurePolygon, timelineFeaturePoints])

  return (
    <ModalDialogContainer maxWidth={'lg'}>
      <DialogContent>
        <Flex flexDirection='column' height='100%' width='100%'>
          <Flex flex={1}>
            <Box height={'100%'} flex={1} mr={2}>
              <Map
                mapRef={mapRef}
                projectId={projectId}
                initialViewState={initialViewState}
                style={{ height: '700px' }}
              >
                <NavigationControl />

                {featurePoint && (
                  <Marker
                    anchor='bottom'
                    longitude={featurePoint.geometry.coordinates[0]}
                    latitude={featurePoint.geometry.coordinates[1]}
                  >
                    <Image
                      alt='point-marker'
                      src='/images/icons/markers/permit_marker_red.svg'
                    />
                  </Marker>
                )}

                {featurePolygon && (
                  <GeoPolygon
                    key={'polygon'}
                    id={'polygon'}
                    promoteId='id'
                    featurePolygon={featurePolygon}
                  />
                )}

                {timelineFeaturePoints?.map((featurePoint) => (
                  <Marker
                    key={featurePoint.id}
                    anchor='bottom'
                    longitude={
                      featurePoint.geometry.coordinates[0] +
                      featurePoint.properties.offset[0]
                    }
                    latitude={
                      featurePoint.geometry.coordinates[1] +
                      featurePoint.properties.offset[1]
                    }
                    style={{
                      zIndex: hoveredState?.id === featurePoint.id ? 2 : 1,
                    }}
                  >
                    <Image
                      onMouseOver={() => {
                        setHoveredState({
                          id: featurePoint.id,
                          source: 'map',
                        })
                      }}
                      onMouseLeave={() => setHoveredState(null)}
                      alt='user-point-marker'
                      src={
                        hoveredState?.id === featurePoint.id
                          ? '/images/icons/markers/user_marker_blue.svg'
                          : '/images/icons/markers/user_marker_white.svg'
                      }
                    />
                  </Marker>
                ))}
              </Map>
            </Box>
            {!!hoveredState && hoveredState.source === 'map' && (
              <Box width='500px' overflow='auto' position='absolute' mr={-2}>
                {flags.timeline_redesign ? (
                  <TimelineItem
                    auditResult={timelineEntriesWithGeolocation?.find(
                      (t) => t.id == hoveredState?.id,
                    )}
                    auditResults={timelineEntriesWithGeolocation}
                    scope='MAP_MODAL'
                  />
                ) : (
                  <TimelineCardLegacy
                    timelineEntry={timelineEntriesWithGeolocation?.find(
                      (t) => t.id == hoveredState?.id,
                    )}
                  />
                )}
              </Box>
            )}
            <Box width='450px' overflow='auto' position='relative' mr={-1}>
              <Box position={'absolute'} width='100%' p={0.5}>
                <TransitionGroup>
                  {timelineEntriesWithGeolocation?.map((t) => {
                    if (flags.timeline_redesign) {
                      return (
                        <TimelineItem
                          scope='MAP_MODAL'
                          key={t.id}
                          auditResult={t}
                          auditResults={timelineEntriesWithGeolocation}
                          onMouseOver={() => {
                            setHoveredState({ id: t.id, source: 'list' })
                          }}
                          onMouseLeave={() => setHoveredState(null)}
                          hovered={hoveredState?.id === t.id}
                        />
                      )
                    }

                    return (
                      <TimelineCardLegacy
                        key={t.id}
                        timelineEntry={t}
                        onMouseOver={() => {
                          setHoveredState({ id: t.id, source: 'list' })
                        }}
                        onMouseLeave={() => setHoveredState(null)}
                        hovered={hoveredState?.id === t.id}
                      />
                    )
                  })}
                </TransitionGroup>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}
