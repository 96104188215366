import React, { createContext, useState } from 'react'
import { AppNotificationGetStatus } from '@core/entities/notifications'

type AppNotificationGetStatusContextType = {
  status: AppNotificationGetStatus
  setStatus: (status: AppNotificationGetStatus) => void
}

export const AppNotificationGetStatusContext = createContext<
  AppNotificationGetStatusContextType | undefined
>(undefined)

export const AppNotificationStatusProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [status, setStatus] = useState<AppNotificationGetStatus>('ALL')

  return (
    <AppNotificationGetStatusContext.Provider value={{ status, setStatus }}>
      {children}
    </AppNotificationGetStatusContext.Provider>
  )
}
