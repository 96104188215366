import React from 'react'
import { Link } from '@common/material'
import { TimelineBlockBase } from '@common/Timeline'
import { Text } from '@fundamentals'

type ComponentProps = {
  title: string
  links: Array<{ href: string; text: string }>
}

export const TimelineMultiLinksBlock: React.FC<ComponentProps> = ({
  title,
  links = [],
}) => {
  if (links.length > 0) {
    return (
      <TimelineBlockBase title={title}>
        {links.map((link, index) => (
          <Link key={index} href={link.href} target='_blank'>
            <Text variant='body2'>{link.text}</Text>
          </Link>
        ))}
      </TimelineBlockBase>
    )
  }
}
