import React from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'
import { localizedStrings } from '@core/strings'
import { Add, FactCheckOutlined, ArticleOutlined } from '@mui/icons-material'
import { PageUrls } from '@core/page-urls'
import { DropdownButton } from '@common/material'
import { useRouter } from 'next/router'
import useFeatureFlags from '@core/providers/useFeatureFlags'

export type SidebarNewButtonProps<> = {
  organisationId: number
  projectId?: number
  collapsed?: boolean
}

export const SidebarNewButton: React.FC<SidebarNewButtonProps> = ({
  organisationId,
  projectId,
  collapsed,
}) => {
  const router = useRouter()
  const { flags } = useFeatureFlags([
    'checklist_form_refactor',
    'permit_form_refactor',
  ])

  const memoisedNewBtnValues = useDeepCompareMemo(() => {
    const dropdownValues = []

    dropdownValues.push({
      title: localizedStrings.newPermit,
      icon: <ArticleOutlined />,
      onSelect: () => {
        if (projectId) {
          router.push(PageUrls.newPermitProject({ organisationId, projectId }))
        } else {
          router.push(PageUrls.newPermit(organisationId))
        }
      },
      'data-test': 'new-permit-btn',
    })

    dropdownValues.push({
      title: localizedStrings.newChecklist,
      icon: <FactCheckOutlined />,
      onSelect: () => {
        if (projectId) {
          router.push(
            PageUrls.newChecklistProject({ organisationId, projectId }),
          )
        } else {
          router.push(PageUrls.newChecklist({ organisationId }))
        }
      },
      'data-test': 'new-checklist-btn',
    })

    return dropdownValues
  }, [organisationId, projectId, flags])

  return (
    <DropdownButton
      label={localizedStrings.new}
      options={memoisedNewBtnValues}
      collapsed={collapsed}
      variant='contained'
      size='medium'
      endIcon={<Add />}
      sx={{ justifyContent: 'space-between', mt: 2 }}
      data-test='new-dropdown-btn'
      fullWidth
      denseOptions
    />
  )
}
