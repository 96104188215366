import { localizedStrings } from '@core/strings'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useModal } from '@common/GlobalModal'
import { DialogContent, Button, Card, Tooltip } from '@common/material'
import { Box, Flex, Text } from '@fundamentals'
import PspdfkitPlansComponent from 'components/modal/pdf/PspdfkitPlansComponent'
import { useGetProjectPlan } from '@core/react-query/features/projects/plans'
import { useNavigationContext } from '@common/hooks'
import { SaveSelectedProjectPlanZonesModal } from './SaveSelectedProjectPlanZonesModal'
import { useGetPlanPermits } from '@core/react-query/features/permits/plans'
import { usePermitTemplatesFilter } from '@core/providers/filters/usePermitTemplatesFilter'
import { useTextInputFilter } from '@core/providers/filters'
import { Filters } from 'webapp/components/elements/Filters/Filters'
import { useDateRangeFilter } from '@core/providers/filters'
import { useFilterValueStore } from '@core/providers/filters'
import { ProjectPlanPermitsSidebar } from '@modules/projectPlan/components/ProjectPlanPermitsSidebar'
import { ProjectPlanZonesSidebar } from '@modules/projectPlan/components/ProjectPlanZonesSidebar'
import { ProjectPlanNavigationTools } from '@modules/projectPlan/components/ProjectPlanNavigationTools'
import {
  ProjectPlanStateContext,
  useProjectPlanMachine,
} from 'webapp/state-machines/projectPlan'
import { useActor } from '@xstate/react'
import { usePermitStatusFilter } from '@core/providers/filters/usePermitStatusFilter'
import { ModalDialogContainer } from '@common/modal'
import { usePlanZonesTooltipPosition } from './usePlanZonesTooltipPosition'

interface ComponentType {
  nodeId: string
  projectId: number
  planId: number
  nodePath: string
  initialSelectedAreas?: string[]
  isSelectionLocal?: boolean
  permitId?: number
  readOnly?: boolean
  onSuccess?: (selectedAreaIds: string[]) => void
}

export const ProjectPlanPermitsModal: React.FC<ComponentType> = ({
  projectId,
  nodeId,
  planId,
  nodePath,
  initialSelectedAreas = [],
  isSelectionLocal,
  permitId,
  readOnly = false,
  onSuccess,
}) => {
  const { organisationId } = useNavigationContext()
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true)
  const { hoveredAnnotation, calcPosition, resetPosition } =
    usePlanZonesTooltipPosition()
  const { hideModal, showModal } = useModal()
  const { data: projectPlan, isFetched } = useGetProjectPlan(
    {
      projectId,
      nodeId,
      planId,
    },
    {
      refetchOnMount: false,
    },
  )

  const pspdfkitRef = useRef(null)

  const projectPlanService = useProjectPlanMachine({
    pspdfkitRef,
    projectId,
    nodeId,
    planId,
    projectPlanMode: isSelectionLocal
      ? 'choosingZonesWithPermits'
      : 'viewingZonesWithPermits',
    initialProjectPlanAreas: initialSelectedAreas,
    colorOpacity: {
      selected: 1,
      deselected: 0.2,
      default: 0.3,
    },
    useImage: false,
  })

  const [projectPlanState, projectPlanSend] = useActor(projectPlanService)
  const {
    PSPDFKit,
    instance,
    projectPlanPSPDFAreas = [],
    selectedAreas: projectPlanPSPDFSelectedAreas = [],
    requestAreas = [],
  } = projectPlanState.context

  const projectPlanPSPDFSelectedAreaIds = projectPlanPSPDFSelectedAreas.map(
    (area) => area.id,
  )

  const filters = {
    statuses: usePermitStatusFilter({
      multiple: true,
    }),
    dateRange: useDateRangeFilter({
      label: localizedStrings.dateRange,
      dataTest: 'date-range-filter',
    }),
    templateIds: usePermitTemplatesFilter({
      label: localizedStrings.permitTemplate,
      scope: {
        type: 'PROJECT',
        projectId,
        organisationId,
      },
      multiple: true,
    }),
    permitUUID: useTextInputFilter({
      label: localizedStrings.uniqueCode,
    }),
  }

  const store = useFilterValueStore({
    filters,
    initialValues: {
      statuses: [
        'OPEN',
        'REQUESTED',
        'ISSUED',
        'EXTENSION_REQUESTED',
        'SENT_TO_PERMIT_HOLDER',
      ],
    },
  })

  const { dateRange, statuses, ...restFilters } = store.values

  const filterQueries = {
    ...(statuses?.length && { statuses }),
    ...(dateRange?.startTime && { startTime: dateRange?.startTime }),
    ...(dateRange?.endTime && { endTime: dateRange?.endTime }),
    ...restFilters,
  }

  const { data: planPermits } = useGetPlanPermits({
    planId,
    projectId,
    ...filterQueries,
  })

  const handleZoneMouseOver = (event, annId?: string) => {
    if (
      (event?.target &&
        (event?.target.closest('.PSPDFKit-Annotation') ||
          event?.target.classList.contains('PSPDFKit-Annotation'))) ||
      (annId &&
        instance?.contentDocument?.querySelector(
          `[data-annotation-id="${annId}"]`,
        ))
    ) {
      // The annotation ID can be obtained from the `data-annotation-id` attribute of
      // the closest `.PSPDFKit-Annotation` element.'

      const annotationElement: HTMLElement =
        event?.target.closest('.PSPDFKit-Annotation') ??
        instance?.contentDocument?.querySelector(
          `[data-annotation-id="${annId}"]`,
        )

      const annotationId: string =
        annotationElement.getAttribute('data-annotation-id')

      const annotation = projectPlanPSPDFAreas.find(
        (annotation) => annotation.id === annotationId,
      )

      const elemRect: DOMRect = annotationElement.getBoundingClientRect()

      const points: number[][] = annotation?.points

      calcPosition(points, elemRect, annotation, isSidebarExpanded)
    }
  }

  const handleZoneMouseOut = async () => {
    resetPosition()
  }

  useEffect(() => {
    if (instance && projectPlanPSPDFAreas) {
      instance.contentDocument.addEventListener(
        'mouseover',
        handleZoneMouseOver,
      )
      instance.contentDocument.addEventListener('mouseout', handleZoneMouseOut)
    }

    return () => {
      if (instance && projectPlanPSPDFAreas) {
        instance.contentDocument.removeEventListener(
          'mouseover',
          handleZoneMouseOver,
        )
        instance.contentDocument.removeEventListener(
          'mouseout',
          handleZoneMouseOut,
        )
      }
    }
  }, [instance, projectPlanPSPDFAreas, isSidebarExpanded])

  const allSelectedPermits =
    planPermits?.getSelectedPermits(
      projectPlanPSPDFSelectedAreas.map((selectedArea) => selectedArea.id),
    ) ?? []

  const filteredPermitsMemoised = useMemo(() => {
    const withoutCurrentPermits = allSelectedPermits.filter(
      (permit) => permit.id !== permitId,
    )

    return withoutCurrentPermits
  }, [JSON.stringify(allSelectedPermits)])

  const currentPermit = planPermits?.getPermit(permitId)

  return (
    <ProjectPlanStateContext.Provider value={{ projectPlanService }}>
      <ModalDialogContainer fullScreen>
        <Tooltip
          arrow
          open={!!hoveredAnnotation}
          disableFocusListener
          disableHoverListener
          disableInteractive
          disableTouchListener
          title={
            !!hoveredAnnotation?.annotation?.fillColor && (
              <Flex bgcolor='white' alignItems='center'>
                <Box
                  p={1}
                  height={34}
                  width={34}
                  minWidth={34}
                  sx={{
                    background: hoveredAnnotation.annotation.fillColor,
                    opacity: hoveredAnnotation.annotation.opacity + 0.3,
                  }}
                />
                <Flex ml={1} flexDirection='column'>
                  <Text fontSize={11} color='text.secondary'>
                    {hoveredAnnotation.annotation.name}
                  </Text>
                  <Text
                    color='text.primary'
                    fontSize={11}
                    fontWeight='bold'
                    sx={{
                      transition: 'height 0.4s ease-out, opacity 0.1s ease-out',
                    }}
                  >
                    {`${planPermits?.permitStats?.getAreaTotalElements(
                      hoveredAnnotation.annotation?.id,
                    )} ${localizedStrings.permits}`}
                  </Text>
                </Flex>
              </Flex>
            )
          }
          placement='top'
          PopperProps={{
            sx: {
              '.MuiTooltip-tooltip': {
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                padding: '8px 8px',
              },
              '.MuiTooltip-arrow:before': {
                color: 'white',
                border: '0.5px solid rgba(0, 0, 0, 0.12)',
              },
            },
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  hoveredAnnotation?.x,
                  hoveredAnnotation?.y + 20,
                  0,
                  0,
                )
              },
            },
          }}
        >
          <Box />
        </Tooltip>
        <Card
          sx={{ p: 2, borderRadius: 0, width: '100%', minHeight: '4.4rem' }}
        >
          <Flex alignItems='center' justifyContent='space-between'>
            <Text variant='h5'>{nodePath}</Text>

            <Box>
              <Button variant='outlined' onClick={() => hideModal()}>
                {readOnly ? localizedStrings.close : localizedStrings.cancel}
              </Button>
              {!readOnly && (
                <Button
                  variant='contained'
                  sx={{ ml: 1 }}
                  onClick={() => {
                    showModal(
                      <SaveSelectedProjectPlanZonesModal
                        newAreas={requestAreas.filter((ann) =>
                          projectPlanPSPDFSelectedAreaIds.includes(ann.uuid),
                        )}
                        onSuccess={() => {
                          hideModal()
                          onSuccess &&
                            onSuccess(projectPlanPSPDFSelectedAreaIds)
                        }}
                      />,
                    )
                  }}
                  data-test='close-permit-submit'
                >
                  {localizedStrings.save}
                </Button>
              )}
            </Box>
          </Flex>
        </Card>
        <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
          <Box mt={1} mx={1}>
            <Filters filters={filters} store={store} />
          </Box>

          <Flex flexDirection='column' height='calc(100vh - 132px)'>
            <Flex height='100%'>
              <ProjectPlanZonesSidebar
                planPermits={planPermits}
                initialSelectedAreas={initialSelectedAreas}
                handleZoneMouseOver={handleZoneMouseOver}
                handleZoneMouseOut={handleZoneMouseOut}
                handleSidebarExpand={setIsSidebarExpanded}
              />
              <Box width='100%' height='100%'>
                {isFetched && projectPlan?.locationPlanDocumentDownloadUrl && (
                  <>
                    <ProjectPlanNavigationTools />
                    <PspdfkitPlansComponent pspdfkitRef={pspdfkitRef} />
                  </>
                )}
              </Box>

              <ProjectPlanPermitsSidebar
                organisationId={organisationId}
                permitId={permitId}
                currentPermit={currentPermit}
                filteredPermitsMemoised={filteredPermitsMemoised}
              />
            </Flex>
          </Flex>
        </DialogContent>
      </ModalDialogContainer>
    </ProjectPlanStateContext.Provider>
  )
}
