import { FeatureCollection, Point } from 'geojson'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { w3wKeys, RequestTypes } from '@core/react-query/features/w3w'

export const useGetW3WToCoords = (
  payload: RequestTypes['getW3WToCoords'],
  options?: UseQueryOptions<FeatureCollection<Point>, string>,
) => {
  return useQuery({
    ...w3wKeys.getW3WToCoords(payload),
    retry: 0,
    ...options,
  })
}
