import { Box, Flex } from '@fundamentals'
import React, { useCallback } from 'react'
import Logo from '@common/Logo'
import { SidebarLink } from './SidebarLink'
import { useRouter } from 'next/router'
import { useDeepCompareEffect } from 'use-deep-compare'
import { SidebarProps, SidebarLinkOption } from './types'
import { useThemeProps } from '@mui/material/styles'
import { Divider } from '@common/material'
// import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import NewReleasesOutlined from '@mui/icons-material/NewReleasesOutlined'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import BeamerWrapper from '@common/BeamerWrapper'
import { loginRedirect } from 'components/loginRedirect'

const Sidebar: React.FC<SidebarProps> = ({
  primarySidebar,
  nestedSidebar,
  collapsePrimarySidebar: collapsePrimarySidebarProp,
  profile,
  ...props
}) => {
  const { flags } = useFeatureFlags(['web_beamer'])
  const [activePrimaryIndex, setActivePrimaryIndex] = React.useState<number>()
  const [activeNestedIndex, setActiveNestedIndex] = React.useState<number>()
  const router = useRouter()
  const pathWithoutQuery = router.asPath.split('?')[0]
  const { sidebarWidth, collapsedSidebarWidth } = useThemeProps({
    name: 'Sidebar',
    props,
  })

  const collapsePrimarySidebar = !!nestedSidebar || collapsePrimarySidebarProp

  const getActiveLink = useCallback<
    (
      sidebarItems: SidebarLinkOption[],
    ) => SidebarLinkOption & { index?: number }
  >(
    (sidebarItems) => {
      return sidebarItems?.reduce((currentActiveItem, item, index) => {
        if (
          pathWithoutQuery.startsWith(item.href) &&
          item.href.startsWith(currentActiveItem?.href || '')
        ) {
          return { ...item, index }
        }
        return currentActiveItem
      }, null)
    },
    [pathWithoutQuery],
  )

  useDeepCompareEffect(() => {
    const activePrimaryLink = getActiveLink(primarySidebar.mainLinks)
    if (activePrimaryLink) setActivePrimaryIndex(activePrimaryLink.index)
  }, [getActiveLink, primarySidebar.mainLinks])

  useDeepCompareEffect(() => {
    if (nestedSidebar) {
      const activeNestedLink = getActiveLink(nestedSidebar.mainLinks)
      if (activeNestedLink) setActiveNestedIndex(activeNestedLink.index)
    }
  }, [getActiveLink, nestedSidebar])

  return (
    <Flex>
      <Flex
        flexDirection='column'
        bgcolor='background.paper'
        width={collapsePrimarySidebar ? collapsedSidebarWidth : sidebarWidth}
      >
        <Flex
          mb={1.5}
          mt={1.1}
          justifyContent={'center'}
          onClick={() => {
            loginRedirect({ profile })
          }}
        >
          <Logo
            type='dark'
            sizeType={collapsePrimarySidebar ? 'small' : 'default'}
          />
        </Flex>

        <Box px={3}>{primarySidebar.header}</Box>

        <Flex flexDirection='column' flex={1} p={2}>
          <Box flex={1}>
            {primarySidebar?.mainLinks?.map((link, key) => {
              return (
                <SidebarLink
                  key={key}
                  href={link.href}
                  title={link.title}
                  iconOnly={collapsePrimarySidebar}
                  icon={link.icon}
                  selectedIcon={link.selectedIcon}
                  active={activePrimaryIndex === key}
                  data-test={link?.dataTest}
                  onClick={link?.onClick}
                />
              )
            })}
          </Box>
          <Box>
            {flags.web_beamer && (
              <BeamerWrapper profile={profile}>
                <SidebarLink
                  title={"What's New"}
                  iconOnly={collapsePrimarySidebar}
                  icon={<NewReleasesOutlined />}
                  selectedIcon={<NewReleasesOutlined />}
                  active={false}
                />
              </BeamerWrapper>
            )}
            {primarySidebar.bottomLinks?.map((link, key) => (
              <SidebarLink
                key={key}
                title={link.title}
                onClick={link.onClick}
                icon={link.icon}
                iconOnly={collapsePrimarySidebar}
                data-test={link?.dataTest}
              />
            ))}
          </Box>
        </Flex>
      </Flex>
      <Divider orientation='vertical' />
      {nestedSidebar?.mainLinks && (
        <>
          <Box width={200} bgcolor='background.paper'>
            <Box>{nestedSidebar.header}</Box>
            {nestedSidebar.mainLinks.map((link, key) => {
              return (
                <SidebarLink
                  key={key}
                  href={link.href}
                  title={link.title}
                  active={activeNestedIndex === key}
                  data-test={link?.dataTest}
                />
              )
            })}
          </Box>
          <Divider orientation='vertical' />
        </>
      )}
    </Flex>
  )
}

export { Sidebar }
