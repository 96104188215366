import React, { ReactNode, useMemo } from 'react'
import { Feature, FeatureCollection, Point, Polygon } from 'geojson'
import { DialogContent } from '@mui/material'
import { Flex, Text } from '@fundamentals'
import { TimelineMap } from '@modules/geolocation'
import { ModalDialogContainer } from '@common/modal'
import { area } from '@turf/turf'
import { localizedStrings } from '@core/strings'

type SimpleMapModalProps = {
  projectId: number
  featurePoint?: Feature<Point>
  featurePolygon?: Feature<Polygon> | FeatureCollection<Polygon>
  userFeaturePoint?: Feature<Point>
  featurePointAccuracyRadius?: Feature<Polygon>
  footerActions?: ReactNode
}

export const SimpleMapModal: React.FC<SimpleMapModalProps> = ({
  projectId,
  featurePoint,
  featurePolygon,
  userFeaturePoint,
  featurePointAccuracyRadius,
  footerActions,
}) => {
  const calculatedSquareMeters = useMemo(() => {
    if (!featurePolygon) return null

    return Math.round(area(featurePolygon))
  }, [featurePolygon])

  return (
    <ModalDialogContainer maxWidth={false}>
      <DialogContent>
        <TimelineMap
          projectId={projectId}
          featurePoint={featurePoint}
          featurePolygon={featurePolygon}
          userFeaturePoint={userFeaturePoint}
          featurePointAccuracyRadius={featurePointAccuracyRadius}
          style={{ height: '75vh', width: '100%', borderRadius: 4 }}
        />
        {calculatedSquareMeters && (
          <Text
            color='text.secondary'
            fontSize={14}
            justifySelf='flex-end'
            sx={{ pt: 2 }}
          >
            {localizedStrings.getTotalDrawnAreaSize(calculatedSquareMeters)}
          </Text>
        )}

        {!!footerActions && (
          <Flex alignItems='center' justifyContent='flex-end' sx={{ pt: 1 }}>
            {footerActions}
          </Flex>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}
