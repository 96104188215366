import { useContext } from 'react'
import { AppNotificationGetStatusContext } from '@modules/notifications'

export const useAppNotificationGetStatus = () => {
  const context = useContext(AppNotificationGetStatusContext)
  if (context === undefined) {
    throw new Error(
      'useAppNotificationGetStatus must be used within an AppNotificationGetStatusProvider',
    )
  }
  return context
}
