import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { area, featureCollection } from '@turf/turf'
import { MapRef } from 'react-map-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import {
  usePermitTemplatesFilter,
  usePermitStatusFilter,
  useDateRangeFilter,
  useFilterValueStore,
} from '@core/providers/filters'
import { useProjectMapPermits } from '@core/react-query/features/permits'
import { DialogContent, Divider, Popper } from '@mui/material'
import { Flex, Box } from '@fundamentals'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import { GeoLocationArea } from '@core/entities/map'
import useVisibleEntitiesOnMap from '@core/providers/useVisibleEntitiesOnMap'
import { areasToGeojson, geojsonToAreas } from '@core/utils/geolocation-helpers'
import { Map, PermitCard } from '@elements'
import { useToast } from '@core/toast'
import { useModal } from '@common/GlobalModal'
import { ModalDialogContainer } from '@common/modal'
import ConfirmModal from '@common/modal/ConfirmModal'
import { Button, DialogTitle, IconButton } from '@common/material'
import {
  calculateMapBounds,
  calculatePositionOfPopper,
  setupClickEffect,
  setupHoverEffect,
  zoomToFeatures,
  hashFeatureCollection,
} from '@modules/geolocation'
import { GeoPointMarkerLayer, GeoPolygonLayer } from '@elements/Geolocation'
import { PermitFilterToolbar } from './PermitFilterToolbar'
import { DrawControlToolbar } from './DrawControlToolbar'
import { projectMapGeoDrawAreaStyles } from './ProjectMapGeoDrawAreaStyle'
import { localizedStrings } from '@core/strings'
import { PermitListSidebar } from './PermitListSideBar'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { Permit } from '@core/entities/permit/Permit'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ClearIcon from '@mui/icons-material/Clear'

type ProjectMapGeoDrawAreaModalProps = {
  projectId: number
  organisationId: number
  initialFilters?: {
    permitStatuses?: PermitStatus[]
    permitTemplate?: number[]
  }
  initialGeoLocationAreas?: GeoLocationArea[]
  onChangeGeoLocationAreas?: (geoLocationAreas: GeoLocationArea[]) => void
  readonly?: boolean
}

export const ProjectMapGeoDrawAreaModal: React.FC<
  ProjectMapGeoDrawAreaModalProps
> = ({
  projectId,
  organisationId,
  initialFilters = {
    permitStatuses: [
      'OPEN',
      'REQUESTED',
      'ISSUED',
      'EXTENSION_REQUESTED',
      'SENT_TO_PERMIT_HOLDER',
    ],
  },
  initialGeoLocationAreas,
  onChangeGeoLocationAreas,
  readonly,
}) => {
  const mapRef = useRef<MapRef>()
  const drawRef = useRef<MapboxDraw>(
    new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: false,
      },
      styles: projectMapGeoDrawAreaStyles,
    }),
  )
  const { showModal, hideModal, hideAllModals } = useModal()
  const toast = useToast()
  const [mode, setMode] = useState<'draw' | 'close' | 'confirm'>('draw')
  const [featureCount, setFeatureCount] = useState(0)
  const [calculatedAreaInSquareMeters, setCalculatedAreaInSquareMeters] =
    useState(0)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [initialHash, setInitialHash] = useState(null)
  const [showExistingPermitsOnMap, setShowExistingPermitsOnMap] = useState(true)
  const [hoveredEntityId, setHoveredEntityId] = useState(null)
  const [popupEntity, setPopupEntity] = useState<{
    id: number
    clientX: number
    clientY: number
  }>(null)

  const filters = {
    status: usePermitStatusFilter({
      multiple: true,
    }),
    permitTemplate: usePermitTemplatesFilter({
      isPinned: true,
      label: localizedStrings.permitType,
      multiple: true,
      scope: {
        type: 'PROJECT',
        organisationId,
        projectId,
      },
    }),
    dateRange: useDateRangeFilter({
      isPinned: true,
      label: localizedStrings.dateRange,
      dataTest: 'date-range-filter',
    }),
  }

  const store = useFilterValueStore({
    filters,
    initialValues: {
      status: initialFilters?.permitStatuses,
      permitTemplate: initialFilters?.permitTemplate,
    },
  })

  const { data: permitsWithGeolocation } = useProjectMapPermits(
    {
      projectId,
      templateIds: store.values.permitTemplate?.length
        ? store.values.permitTemplate
        : null,
      permitStatuses: store.values.status?.length ? store.values.status : null,
      startTime: store?.values?.dateRange?.startTime || null,
      endTime: store?.values?.dateRange?.endTime || null,
    },
    {
      onError: (e) => {
        toast.error(e.message)
      },
    },
  )

  const { setMapBounds, visibleEntitiesOnMap: visiblePermitsOnMap } =
    useVisibleEntitiesOnMap(permitsWithGeolocation)

  const permitZones = useMemo(() => {
    const featurePolygons = permitsWithGeolocation
      ?.filter((permit) => !!permit.getGeolocationAreas())
      ?.flatMap((permit) => {
        const featureCollectionPolygon = permit.getGeolocationAreas()
        const featurePolygons = featureCollectionPolygon.features.map(
          (featurePolygon) => {
            featurePolygon.properties.id = uuidv4()
            featurePolygon.properties.permitId = permit.id
            featurePolygon.properties.selected = false

            return featurePolygon
          },
        )

        return featurePolygons
      })
      ?.sort((zone1, zone2) => {
        const sizeZone1 = area(zone1)
        const sizeZone2 = area(zone2)
        return sizeZone2 - sizeZone1
      })

    return featureCollection(featurePolygons)
  }, [permitsWithGeolocation])

  const permitMarkers = useMemo(() => {
    const featurePoints = permitsWithGeolocation
      ?.filter((permit) => !!permit.getW3wLocation())
      ?.map((permit) => {
        const featurePoint = permit.getW3wLocation()
        featurePoint.properties.id = uuidv4()
        featurePoint.properties.permitId = permit.id
        featurePoint.properties.selected = false

        return featurePoint
      })

    return featureCollection(featurePoints)
  }, [permitsWithGeolocation])

  const hoveredPermit = useMemo(() => {
    if (!hoveredEntityId && !permitsWithGeolocation) {
      return null
    }

    return permitsWithGeolocation?.find(
      (permit) => permit.id === hoveredEntityId,
    )
  }, [hoveredEntityId])

  const popupPermit = useMemo(() => {
    if (!popupEntity) {
      return null
    }

    return permitsWithGeolocation?.find(
      (permit) => permit.id === popupEntity.id,
    )
  }, [popupEntity])

  const handleOnPermitPopupDetails = useCallback((permit: Permit) => {
    showModal(
      <PermitDetailsModal
        permitId={permit.id}
        organisationId={permit.organisationId}
      />,
    )
    setPopupEntity(null)
  }, [])

  const handleOnMapClick = (event, feature) => {
    const selectedDrawnAreaIds = drawRef.current.getSelectedIds()
    setCanDelete(selectedDrawnAreaIds?.length > 0)

    if (feature) {
      const clientX = event.originalEvent.clientX
      const clientY = event.originalEvent.clientY

      setPopupEntity({
        id: feature.properties.permitId,
        clientX,
        clientY,
      })

      return
    }

    setPopupEntity(null)
  }

  const onLoad = () => {
    mapRef.current.addControl(drawRef.current)

    mapRef.current.on('draw.create', onDrawUpdate)
    mapRef.current.on('draw.delete', onDrawUpdate)
    mapRef.current.on('draw.update', onDrawUpdate)
    mapRef.current.on('draw.modechange', onMapboxDrawModeChange)

    if (initialGeoLocationAreas) {
      const initialGeoJson = areasToGeojson(initialGeoLocationAreas)

      setInitialHash(hashFeatureCollection(initialGeoJson))

      if (initialGeoJson.features.length > 0) {
        // Calculate the area of the drawn polygons in square meters
        const calculatedSquareMeters = Math.round(area(initialGeoJson))
        setCalculatedAreaInSquareMeters(calculatedSquareMeters)

        const bounds = calculateMapBounds(initialGeoJson.features)
        mapRef.current.fitBounds(bounds, { animate: false })

        drawRef?.current?.add(initialGeoJson)
      }
    }

    const bounds = mapRef.current.getBounds()
    setMapBounds({
      nw: bounds.getNorthWest(),
      se: bounds.getSouthEast(),
    })

    setupHoverEffect(
      mapRef.current,
      [
        'permit-zones-fill-layer',
        'permit-zones-line-layer',
        'permit-markers-symbol-layer-unselected',
        'permit-markers-symbol-layer-selected',
      ],
      ['permit-zones-shape-source', 'permit-markers-shape-source'],
      (permitId, action) => {
        if (action === 'select') {
          setHoveredEntityId(permitId)
          return
        }

        if (action === 'deselect') {
          setHoveredEntityId(null)
        }
      },
    )

    setupClickEffect(mapRef.current, handleOnMapClick)
  }

  const onMoveEnd = () => {
    const bounds = mapRef.current.getBounds()
    setMapBounds({
      nw: bounds.getNorthWest(),
      se: bounds.getSouthEast(),
    })
  }

  const syncDrawState = () => {
    const featureCollection = drawRef?.current?.getAll()
    setFeatureCount(featureCollection?.features.length)

    // Calculate the area of the drawn polygons in square meters
    const calculatedSquareMeters = Math.round(area(featureCollection))
    setCalculatedAreaInSquareMeters(calculatedSquareMeters)

    // Hash the current feature collection and compare with the initial hash
    const currentHash = hashFeatureCollection(featureCollection)
    setHasUnsavedChanges(currentHash !== initialHash)
  }

  const onDrawUpdate = (event) => {
    syncDrawState()

    setMode('draw')
  }

  const onMapboxDrawModeChange = (event) => {
    if (event.mode === 'simple_select') {
      setMode('draw')
    }
  }

  const handleOnDrawNewArea = () => {
    drawRef?.current?.changeMode('simple_select')
    drawRef?.current?.changeMode('draw_polygon')

    setMode('close')
    setCanDelete(false)
  }

  const handleOnDeleteArea = () => {
    const selectedFeatureIds = drawRef?.current?.getSelectedIds()

    drawRef?.current?.delete(selectedFeatureIds)

    syncDrawState()

    setCanDelete(false)
    setMode('draw')
  }

  const handleOnCloseArea = () => {
    drawRef?.current?.changeMode('simple_select')

    setMode('draw')
  }

  const handleOnSaveAreas = () => {
    const featureCollection = drawRef?.current?.getAll()

    zoomToFeatures({
      mapRef: mapRef,
      features: featureCollection?.features,
      radius: 60,
    })

    setMode('confirm')
  }

  const handleOnConfirmAreas = () => {
    const featureCollection = drawRef?.current?.getAll()
    const areas = geojsonToAreas(featureCollection)

    onChangeGeoLocationAreas(areas)

    setMode('draw')

    hideModal()
  }

  const handleOnCloseModal = () => {
    if (hasUnsavedChanges) {
      showModal(
        <ConfirmModal
          title={localizedStrings.unsavedChanges}
          dismissText={localizedStrings.noExit}
          confirmText={localizedStrings.saveNow}
          onDismiss={() => {
            hideAllModals()
          }}
          onConfirm={() => {
            handleOnConfirmAreas()
            hideAllModals()
          }}
          onClose={() => {
            hideModal()
          }}
        >
          {localizedStrings.ifYouExitWithoutSaving}
        </ConfirmModal>,
      )

      return
    }

    hideModal()
  }

  // Cleanup listeners to prevent memory leaks when component unmounts
  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.off('draw.create', onDrawUpdate)
        mapRef.current.off('draw.delete', onDrawUpdate)
        mapRef.current.off('draw.update', onDrawUpdate)
        mapRef.current.off('draw.modechange', onMapboxDrawModeChange)
      }
    }
  }, [])

  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '90%',
        },
      }}
      onClose={handleOnCloseModal}
    >
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.pointOfWorks}
        <IconButton aria-label='close-modal' onClick={handleOnCloseModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex flexDirection='column' height='100%' width='100%'>
          <Flex>
            <PermitFilterToolbar
              filters={filters}
              store={store}
              showPermits={showExistingPermitsOnMap}
              setShowPermits={setShowExistingPermitsOnMap}
            />
          </Flex>

          <Flex flex={1}>
            <Box height='100%' flex={1} mr={2}>
              <Map
                mapRef={mapRef}
                projectId={projectId}
                onLoad={onLoad}
                onMoveEnd={onMoveEnd}
                style={{ height: '60vh', width: '100' }}
              >
                {showExistingPermitsOnMap &&
                  permitZones?.features?.length !== 0 && (
                    <GeoPolygonLayer
                      key={'permit-zones'}
                      id={'permit-zones'}
                      promoteId='permitId'
                      featureCollection={permitZones}
                    />
                  )}

                {showExistingPermitsOnMap &&
                  permitMarkers?.features?.length !== 0 && (
                    <GeoPointMarkerLayer
                      key={'permit-markers'}
                      id={'permit-markers'}
                      promoteId='permitId'
                      featureCollection={permitMarkers}
                    />
                  )}
              </Map>
            </Box>

            {popupPermit && (
              <Popper
                id={'popup'}
                open={true}
                anchorEl={calculatePositionOfPopper(
                  popupEntity.clientX,
                  popupEntity.clientY,
                )}
                // Necessary so that the element is on top of modals
                style={{ zIndex: 15000 }}
              >
                <Box width={'350px'}>
                  <PermitCard
                    templateName={popupPermit?.template?.name}
                    {...popupPermit}
                    sx={{ mx: 1, mt: 1 }}
                  >
                    <Flex mt={1}>
                      <Button
                        variant='contained'
                        size='small'
                        sx={{ flexGrow: 1, mr: 1 }}
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={() => handleOnPermitPopupDetails(popupPermit)}
                      >
                        {localizedStrings.details}
                      </Button>
                    </Flex>
                  </PermitCard>
                </Box>
              </Popper>
            )}

            {!popupPermit && hoveredPermit && (
              <Box width='300px' overflow='auto' position='absolute'>
                <PermitCard
                  templateName={hoveredPermit?.template?.name}
                  {...hoveredPermit}
                  sx={{ mx: 1, mt: 1, borderColor: 'primary.main' }}
                />
              </Box>
            )}

            <Box width='300px' overflow='auto' position='relative' mr={-2}>
              <PermitListSidebar
                mapRef={mapRef}
                permits={visiblePermitsOnMap}
                hoveredEntityId={hoveredEntityId}
                setHoveredEntityId={setHoveredEntityId}
              />
            </Box>
          </Flex>

          <Divider />

          {!readonly && (
            <DrawControlToolbar
              mode={mode}
              onDrawNewArea={handleOnDrawNewArea}
              onDeleteArea={handleOnDeleteArea}
              onCloseArea={handleOnCloseArea}
              onSaveAreas={handleOnSaveAreas}
              onConfirmAreas={handleOnConfirmAreas}
              featureCount={featureCount}
              squareMeters={calculatedAreaInSquareMeters}
              canDelete={canDelete}
            />
          )}
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}
