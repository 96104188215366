import { useRouter } from 'next/router'

export const useNavigationContext = () => {
  const router = useRouter()
  const asPathWithoutQuery = router.asPath.split('?')[0]
  const { query } = router

  const inSupportContext = router.pathname.startsWith('/support')

  const organisationId = query?.id && parseInt(`${query.id}`)
  const folderId = query?.folderId && parseInt(`${query.folderId}`)
  const documentId = query?.documentId && parseInt(`${query.documentId}`)

  const accessToken = query?.accessToken && `${query.accessToken}`

  const inBusinessUnitContext = asPathWithoutQuery.includes('/business-units')
  const businessUnitId =
    query?.businessUnitId && parseInt(`${query.businessUnitId}`)

  const inProjectContext = asPathWithoutQuery.includes('/projects')
  const projectId = query?.projectId && parseInt(`${query.projectId}`)

  const inPermitContext = asPathWithoutQuery.includes('/permits')
  const permitId = query?.permitId && parseInt(`${query.permitId}`)

  const inTemplateContext = asPathWithoutQuery.includes('/templates')

  const templateId = query?.templateid && parseInt(`${query.templateid}`)

  const inChecklistVersionContext =
    asPathWithoutQuery.includes('/checklist-templates') &&
    asPathWithoutQuery.includes('/versions')
  const checklistId = query?.checklistId && parseInt(`${query.checklistId}`)
  const checklistTemplateId =
    query?.checklistTemplateId && parseInt(`${query?.checklistTemplateId}`)
  const checklistTemplateVersionId =
    inChecklistVersionContext &&
    query?.versionId &&
    parseInt(`${query.versionId}`)

  const inTemplateVersionContext =
    asPathWithoutQuery.includes('/templates') &&
    asPathWithoutQuery.includes('/versions')
  const templateVersionId = query?.version && parseInt(`${query.version}`)

  const inRegisterDiagramsContext = asPathWithoutQuery.includes('/diagrams')

  const inRegisterTemplateContext = asPathWithoutQuery.includes(
    '/register-templates',
  )

  const registerTemplateId =
    inRegisterTemplateContext && parseInt(`${query?.templateId}`)

  const userId = query?.userId && parseInt(`${query.userId}`)

  const authorisationGroupId = query?.groupId && parseInt(`${query.groupId}`)
  const dashboardId = query?.dashboardId && parseInt(`${query?.dashboardId}`)
  const inAnalyticsContext = asPathWithoutQuery.includes('/analytics')

  const adminUserId = query?.adminUserId && parseInt(`${query?.adminUserId}`)

  const registerId = query?.registerId && parseInt(`${query.registerId}`)
  const registerItemId =
    query?.registerItemId && parseInt(`${query.registerItemId}`)
  const registerItemViewId =
    registerItemId && query?.viewId && parseInt(`${query.viewId}`)

  const nodeId = query?.nodeId && query.nodeId
  const planId = query?.planId && parseInt(`${query.planId}`)
  const nodePath = query?.nodePath && query.nodePath
  const locationArchived = query?.locationArchived && Boolean(query.locationId)

  const inProjectSettingsContext =
    asPathWithoutQuery.includes('/projects') &&
    asPathWithoutQuery.includes('/settings')

  const inOrganisationSettingsContext =
    asPathWithoutQuery.includes('/settings') && !inProjectSettingsContext

  const inspectionId = query?.inspectionId && `${query.inspectionId}`
  const inspectionTemplateId =
    query?.inspectionTemplateId && parseInt(`${query.inspectionTemplateId}`)

  return {
    asPathWithoutQuery,
    inSupportContext,
    organisationId,
    inProjectContext,
    projectId,
    inTemplateContext,
    templateId,
    inPermitContext,
    permitId,
    inBusinessUnitContext,
    businessUnitId,
    inTemplateVersionContext,
    templateVersionId,
    userId,
    authorisationGroupId,
    folderId,
    documentId,
    accessToken,
    checklistId,
    checklistTemplateId,
    checklistTemplateVersionId,
    inChecklistVersionContext,
    dashboardId,
    inAnalyticsContext,
    adminUserId,
    registerId,
    registerItemId,
    registerItemViewId,
    nodeId,
    planId,
    nodePath,
    locationArchived,
    inRegisterTemplateContext,
    registerTemplateId,
    inProjectSettingsContext,
    inOrganisationSettingsContext,
    inRegisterDiagramsContext,
    inspectionId,
    inspectionTemplateId,
  }
}
