import React, { useEffect, useState } from 'react'
import { Box } from '@fundamentals'
import { IconButton, Tooltip } from '@common/material'
import { ContentCopyOutlined, LockOutlined } from '@mui/icons-material'
import { localizedStrings } from '@core/strings'
import { RegisterItemFlatPropertyData } from '@core/entities/register/RegisterItem'
import { useDeepCompareMemo } from 'use-deep-compare'
import moment from 'moment'
import { RegisterItemContentVariant } from '@modules/registers/items/types'

type RegisterItemPropertyValueButtons = {
  value: string
  registerItemPropertyData: RegisterItemFlatPropertyData
  children?: React.ReactNode
  variant: RegisterItemContentVariant
  options?: React.ReactNode
}
export const RegisterItemContentButtons: React.FC<
  RegisterItemPropertyValueButtons
> = ({ value, registerItemPropertyData, children, variant, options }) => {
  const [buttonVisible, setButtonVisible] = useState(false)
  const [showCopiedSuccess, setShowCopiedSuccess] = useState(false)

  useEffect(() => {
    if (showCopiedSuccess) {
      const timeout = setTimeout(() => {
        setShowCopiedSuccess(false)
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [showCopiedSuccess])

  const overrideItemTooltip = useDeepCompareMemo(() => {
    const overrideDateString = moment(
      registerItemPropertyData?.formulaOverriddenOn,
    ).format('Do MMM YYYY')
    const overrideTimeString = moment(
      registerItemPropertyData?.formulaOverriddenOn,
    ).format('HH:mm')

    return registerItemPropertyData?.formulaOverride ? (
      <Tooltip
        title={`${localizedStrings.override}: ${registerItemPropertyData?.formulaOverriddenBy?.firstName} ${registerItemPropertyData?.formulaOverriddenBy?.lastName} - ${overrideDateString} - ${overrideTimeString}`}
      >
        <IconButton>
          <LockOutlined />
        </IconButton>
      </Tooltip>
    ) : null
  }, [registerItemPropertyData?.formulaOverride])

  return (
    <Box
      position='relative'
      width='100%'
      flexGrow={1}
      height='100%'
      alignContent='center'
      onMouseEnter={() => setButtonVisible(true)}
      onMouseLeave={() => setButtonVisible(false)}
    >
      {children}
      {variant !== 'picker' && buttonVisible && !!value && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: 'calc(100% - 36px)',
              transform: 'translateY(-50%)',
            }}
          >
            {options ? (
              options
            ) : (
              <Tooltip
                title={
                  showCopiedSuccess
                    ? localizedStrings.copiedToClipBoard
                    : localizedStrings.copyToClipboard
                }
                arrow
              >
                <IconButton
                  onClick={() => {
                    setShowCopiedSuccess(true)
                    copyTextToClipboard(value)
                  }}
                >
                  <ContentCopyOutlined fontSize={'small'} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {overrideItemTooltip && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: 'calc(100% - 10px)',
                transform: 'translateY(-50%)',
              }}
            >
              {overrideItemTooltip}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}
