import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LoadingButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { Text } from '@fundamentals'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { useFormik } from 'formik'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'
import { Permit } from '@core/entities/permit/Permit'
import { useNominateApprovers } from '@core/react-query/features/permits/permit'

type ComponentType = {
  permit: Permit
  onDismiss: () => void
  onNominate: () => void
}

const NominateApproverModal: React.FC<ComponentType> = ({
  permit,
  onDismiss = () => {},
  onNominate = () => {},
}) => {
  const options =
    permit?.approvalReviews?.nominatedApprovers?.suggestedApprovers || []
  const { hideModal } = useModal()
  const mutation = useNominateApprovers()
  const toast = useToast()
  const nominatedApprovers = permit?.approvalReviews?.nominatedApprovers
  const targetApprovers =
    nominatedApprovers?.approvers?.[0]?.targetApprovers ?? []

  const formik = useFormik({
    initialValues: {
      approvers:
        targetApprovers.map((targetApprover) => ({
          targetApproverId: targetApprover.id,
        })) || [],
    },
    onSubmit: ({ approvers }) => {
      mutation.mutate(
        {
          permitId: permit.id,
          approvers: approvers.filter(
            ({ targetApproverId }) =>
              !targetApprovers.find(({ id }) => id === targetApproverId),
          ),
        },
        {
          onSuccess: () => {
            onNominate()
          },
          onError: (e) => toast.error(e.message),
        },
      )
    },
  })

  const renderValue = (value) => {
    return value
      .map((v) => {
        return options.find((option) => option.id === v)?.name
      })
      .join(', ')
  }

  const onChange = (e) => {
    const { value } = e.target
    formik.setFieldValue(
      'approvers',
      value?.map((v) => ({ targetApproverId: v })) || [],
    )
  }

  const onSkip = () => {
    hideModal()
    onDismiss()
  }

  let title = localizedStrings.nominateAnApprover
  const approval = permit.getNextApproval()
  if (approval && approval.approval.label !== 'Default Approval') {
    title += ` – ${approval.approval.label}`
  }

  return (
    <ModalDialogContainer preventDialogClose>
      <form onSubmit={formik.handleSubmit} data-test='nominate-approver-form'>
        <DialogTitle
          variant='h5'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pr: 1, fontSize: 20 }}
        >
          {title}
          <IconButton
            aria-label='close-modal'
            onClick={onSkip}
            data-test='close-btn'
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          <Text mb={3}>{localizedStrings.nominateAnApproverText}</Text>
          <FormControl fullWidth>
            <InputLabel id='select-label'>
              {localizedStrings.approver}
            </InputLabel>
            <Select
              displayEmpty={
                !!formik.values.approvers?.map((v) => v.targetApproverId)
                  ?.length
              }
              labelId='select-label'
              id='approvers'
              value={
                formik.values.approvers?.map((v) => v.targetApproverId) || []
              }
              label={localizedStrings.approver}
              renderValue={renderValue}
              onChange={onChange}
              data-test='select-approver'
              multiple
            >
              {options.map((v, index) => (
                <MenuItem
                  value={v.id}
                  key={v.id}
                  data-test={`select-approver-option-${index}`}
                  disabled={
                    !!targetApprovers?.find(
                      (targetApprover) => targetApprover.id === v.id,
                    )
                  }
                >
                  {v.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions sx={{ pb: 2, pr: 3 }}>
          <Button variant='outlined' onClick={onSkip} data-test='skip-btn'>
            {localizedStrings.skip}
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            variant='contained'
            onClick={() => formik.handleSubmit()}
            disabled={formik.values.approvers.length === 0 || !formik.dirty}
            data-test='confirm-btn'
          >
            {localizedStrings.confirm}
          </LoadingButton>
        </DialogActions>
      </form>
    </ModalDialogContainer>
  )
}

export default NominateApproverModal
