import { ControllerFieldState } from 'react-hook-form'
import { Section } from '@common/Section'
import { Button } from '@common/material'
import { Text } from '@fundamentals'
import { localizedStrings } from '@core/strings'
import LocationOnIcon from '@mui/icons-material/LocationOn'
type W3WMapCardProps = {
  what3words: string
  readOnly?: boolean
  fieldState?: ControllerFieldState
  onClick: () => void
}

export const W3WMapCard: React.FC<W3WMapCardProps> = ({
  what3words,
  readOnly,
  fieldState,
  onClick,
}) => {
  return (
    <Section
      label={localizedStrings.pointOfWorks}
      sx={{
        mr: 1,
        mt: 2,
        mb: !readOnly ? 3 : 1,
      }}
    >
      {!readOnly && !what3words && (
        <Button
          color={fieldState.error ? 'error' : undefined}
          startIcon={<LocationOnIcon />}
          sx={{ mr: 1, mt: 1 }}
          variant='outlined'
          fullWidth
          size='large'
          onClick={onClick}
        >
          {localizedStrings.what3Words}
        </Button>
      )}

      {what3words && (
        <Button
          startIcon={<LocationOnIcon />}
          sx={{
            mr: 1,
            mt: 1,
            color: 'text.secondary',
            borderColor: 'grey.400',
          }}
          variant='outlined'
          fullWidth
          size='large'
          color={'inherit'}
          onClick={onClick}
        >
          {what3words.toLowerCase()}
        </Button>
      )}

      <Text color='error' fontSize={12} ml={2} mt={1}>
        {fieldState?.error?.message}
      </Text>
    </Section>
  )
}
