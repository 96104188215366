import { Link as MaterialLink, LinkProps } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

type ComponentType = LinkProps & {}

const StyledLink = styled(MaterialLink)(({ theme }) =>
  theme.unstable_sx({
    curspor: 'pointer',
    color: 'primary',
  }),
)

const Link: React.FC<ComponentType> = ({ ...props }) => {
  return <StyledLink sx={{ color: 'primary' }} {...props} />
}

export { Link }
