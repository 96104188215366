import { localizedStrings } from '@core/strings'
import React from 'react'
import { useRouter } from 'next/router'
import { Box, Flex, Text } from '@fundamentals'
import {
  ArrowDropDown,
  CorporateFareOutlined,
  SupervisedUserCircle,
} from '@mui/icons-material'
import { CircularProgress, DropdownButton, Tooltip } from '@common/material'
import { PageUrls } from '@core/page-urls'
import { StyledOrganisationButton } from '../NavigationAwareSidebar/OrganisationMenu'
import { useGetAllProjects } from '@core/react-query/features/projects'

export type SidebarProjectPickerProps = {
  organisationId: number
  projectId: number
  collapse?: boolean
}

export const SidebarProjectPicker: React.FC<SidebarProjectPickerProps> = ({
  organisationId,
  projectId,
  collapse,
}) => {
  const { data: projects, isLoading: projectsLoading } = useGetAllProjects({
    organisationId,
  })
  const router = useRouter()
  const currentProject = projects?.find((p) => p.id === projectId)

  const truncationCharacters = 25
  const isTruncated = currentProject?.name?.length > truncationCharacters
  const truncatedName = currentProject?.name?.substring(0, truncationCharacters)

  const renderButton = (onClick) => {
    return (
      <StyledOrganisationButton onClick={onClick}>
        <Flex width={'100%'}>
          <Box ml={1} sx={{ alignSelf: 'center' }}>
            <CorporateFareOutlined />
          </Box>
          {!collapse && (
            <Flex flex={1} flexDirection='column' justifyContent='center'>
              {!currentProject || projectsLoading ? (
                <CircularProgress size={16} sx={{ color: 'common.white' }} />
              ) : (
                <>
                  <Tooltip title={currentProject?.name}>
                    <Text
                      pl={1}
                      mt={0.5}
                      fontSize={14}
                      fontWeight='bold'
                      lineHeight='1.2'
                    >
                      {currentProject?.name
                        ? `${currentProject?.name.substring(0, 25)}${
                            isTruncated ? '...' : ''
                          }`
                        : ''}
                    </Text>
                  </Tooltip>
                </>
              )}
            </Flex>
          )}
        </Flex>
        <ArrowDropDown />
      </StyledOrganisationButton>
    )
  }
  return (
    <>
      <DropdownButton
        label={localizedStrings.organisation}
        options={projects?.map((project) => ({
          title: project.name,
          value: project.id,
          icon: <SupervisedUserCircle />,
        }))}
        onSelect={(projectId) =>
          router.push(PageUrls.projectOverview({ organisationId, projectId }))
        }
        fullWidth
        renderButton={renderButton}
      />
    </>
  )
}
