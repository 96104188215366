import React from 'react'
import { Text } from '@fundamentals'
import { SxProps, Theme } from '@mui/material/styles'

type PageTitleProps = {
  title: string
  sx?: SxProps<Theme>
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, sx }) => {
  return (
    <Text variant={'h6'} sx={{ mb: 1, ...sx }}>
      {title}
    </Text>
  )
}
