import React, { useCallback, useState } from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { useUpdateNotification } from '@core/react-query/features/notifications/hooks'
import { useGetProfile } from '@core/react-query/features/profile'
import {
  AppNotification,
  AppNotificationReferenceObject,
} from '@core/entities/notifications'
import {
  CheckCircleOutlineOutlined,
  MarkEmailUnreadOutlined,
} from '@mui/icons-material'
import { useRouter } from 'next/router'
import { PageUrls } from '@core/page-urls'
import { Divider, IconButton } from '@common/material'
import { alpha } from '@mui/material'
import { useDrawer } from '@common/GlobalDrawer'

type Props = {
  notification: AppNotification
  readOnly?: boolean
}

const TOUCHABLE_REFERENCE_OBJECTS: AppNotificationReferenceObject[] = [
  'PERMIT',
  'CHECKLIST',
]

const NotificationsListItem: React.FC<Props> = ({
  notification,
  readOnly = false,
}) => {
  const router = useRouter()
  const { hideDrawer } = useDrawer()
  const { data: profile } = useGetProfile()
  const { mutate: updateNotification } = useUpdateNotification()

  const [showItemAction, setShowItemAction] = useState(false)

  const { isRead } = notification

  const isNotificationTouchable = TOUCHABLE_REFERENCE_OBJECTS.includes(
    notification.referenceObjectType,
  )

  const onNotificationPress = useCallback(() => {
    if (!isRead) {
      updateNotification({
        userId: profile.id,
        notificationId: notification.id,
        read: true,
      })
    }

    if (notification.isTargetResultOfType('PERMIT')) {
      router.push(
        PageUrls.projectPermitDetail({
          organisationId: notification.targetResult.organisationId,
          projectId: notification.targetResult.project.id,
          permitId: notification.targetResult.id,
        }),
      )
    } else if (notification.isTargetResultOfType('CHECKLIST')) {
      router.push(
        PageUrls.projectChecklistDetail({
          organisationId: notification.targetResult.organisationId,
          projectId: notification.targetResult.project.id,
          checklistId: notification.targetResult.id,
        }),
      )
    }

    hideDrawer()
  }, [isRead])

  return (
    <>
      <Box
        px={3}
        py={2}
        onClick={!readOnly ? onNotificationPress : undefined}
        onMouseOver={() => setShowItemAction(true)}
        onMouseOut={() => setShowItemAction(false)}
        sx={{
          cursor: isNotificationTouchable ? 'pointer' : 'default',
          '&:hover': isNotificationTouchable && { bgcolor: 'grey.100' },
        }}
        bgcolor='white'
      >
        <Flex alignItems='center' width='100%' position='relative'>
          {!isRead && (
            <Box
              height={8}
              width={8}
              borderRadius={8}
              bgcolor='primary.main'
              mr={1}
            />
          )}

          <Text fontWeight={isRead ? 'normal' : 'medium'}>
            {notification.getTitle()}
          </Text>
          {!readOnly && showItemAction && (
            <Box
              position='absolute'
              top={3}
              right={0}
              border={1}
              borderColor={alpha('#000', 0.23)}
              borderRadius={1}
              bgcolor='white'
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  updateNotification({
                    userId: profile.id,
                    notificationId: notification.id,
                    read: !isRead,
                  })
                }}
                size='small'
                sx={{ borderRadius: 1, px: 1, py: 0.5 }}
              >
                {isRead ? (
                  <MarkEmailUnreadOutlined fontSize='small' />
                ) : (
                  <CheckCircleOutlineOutlined fontSize='small' />
                )}
              </IconButton>
            </Box>
          )}
        </Flex>
        <Text fontSize={14} color='text.secondary'>
          {notification.getDescription({
            organisations: profile?.organisations ?? [],
          })}
        </Text>
        <Text fontSize={12} color='text.disabled'>
          {notification.getFormattedCreatedOn()}
        </Text>
      </Box>
      <Divider />
    </>
  )
}

const MemoizedNotificationsListItem = React.memo(
  NotificationsListItem,
  (prevProps, nextProps) =>
    // Only re-render if the id or read status changes
    // Those are the only properties that can change
    prevProps.notification.id === nextProps.notification.id &&
    prevProps.notification.isRead === nextProps.notification.isRead,
)

export { MemoizedNotificationsListItem as NotificationsListItem }
