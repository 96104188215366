import { Entity } from '@core/entities/entity'
import { plainToInstance } from 'class-transformer'
import { IUser } from './IUser'

export class User<
  ExtendedType extends IUser = IUser,
> extends Entity<ExtendedType> {
  id: number

  email: string

  firstName: string

  lastName: string

  phone: string

  company: string

  avatarUrl: string

  public static new(payload: unknown): User {
    const entity = plainToInstance<User, unknown>(User, payload)

    return entity
  }

  public getFullName(): string {
    if (!this.firstName) return ''
    return `${this.firstName} ${this.lastName || ''}`
  }
}
