import React from 'react'
import { PageUrls } from '@core/page-urls'
import { Link, Image } from '@fundamentals'
import { LogoSizeTypes } from '@core/entities/logo'

interface Logo {
  sizeType: LogoSizeTypes
  type: string
}

type logoPropsType = {
  [K in LogoSizeTypes]: {
    width: number
    height: number
    src: string
  }
}

const logoProps: logoPropsType = {
  ['small']: {
    width: 34,
    height: 28,
    src: '/images/logo/logo-light-mark.svg',
  },
  ['default']: {
    width: 160,
    height: 25,
    src: '/images/logo/logo-light-wordmark.svg',
  },
}

const logoPropsDarkTheme: logoPropsType = {
  ['small']: {
    width: 34,
    height: 28,
    src: '/images/logo/logo-dark-mark.svg',
  },
  ['default']: {
    width: 140,
    height: 25,
    src: '/images/logo/logo-dark-wordmark.svg',
  },
}

const Logo: React.FC<Logo> = ({ sizeType = 'default', type = 'light' }) => {
  return (
    <Link href={PageUrls.home} p={1}>
      <Image
        alt='logo'
        {...(type === 'light'
          ? logoProps[sizeType]
          : logoPropsDarkTheme[sizeType])}
      />
    </Link>
  )
}

export default Logo
