import { Box, Flex, Text } from '@common/fundamentals'
import {
  useGetNotifications,
  useMarkAllNotificationsAsRead,
  useMarkAllNotificationsAsSeen,
} from '@core/react-query/features/notifications'
import { useGetProfile } from '@core/react-query/features/profile'
import { useToast } from '@core/toast'
import { useEffect, useMemo } from 'react'
import _flatten from 'lodash/flatten'
import { NotificationsListItem } from './NotificationListItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { localizedStrings } from '@core/strings'
import { PageTitle } from '@elements'
import {
  Clear,
  DoneAllOutlined,
  InboxOutlined,
  MoreHorizOutlined,
  NotificationsOutlined,
} from '@mui/icons-material'
import {
  CircularProgress,
  Divider,
  DropdownButton,
  IconButton,
} from '@common/material'
import { useDrawer } from '@common/GlobalDrawer'
import { useAppNotificationGetStatus } from '@modules/notifications'
import { TransitionGroup } from 'react-transition-group'
import { Collapse } from '@mui/material'

export const NotificationsList = ({ pageSize = 25 }) => {
  const toast = useToast()
  const { hideDrawer } = useDrawer()
  const { data: profile } = useGetProfile()
  const { status: filter, setStatus: setFilter } = useAppNotificationGetStatus()

  const { mutate: markAllNotificationsAsSeen } = useMarkAllNotificationsAsSeen()
  const { mutate: markAllNotificationsAsRead } = useMarkAllNotificationsAsRead()

  const {
    data: notificationsData,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useGetNotifications(
    { userId: profile?.id, status: filter, size: pageSize },
    {
      enabled: !!profile,
      onError: (error) => {
        toast.error(String(error?.message))
      },
    },
  )

  const flattenNotifications = useMemo(
    () => _flatten(notificationsData?.pages),
    [notificationsData?.pages],
  )

  const unreadNotifications = useMemo(
    () => flattenNotifications.filter((notification) => !notification.isRead),
    [flattenNotifications],
  )

  const notifications = useMemo(() => {
    const filteredNotifications =
      filter === 'UNREAD' ? unreadNotifications : flattenNotifications

    return filteredNotifications
  }, [unreadNotifications, filter])

  const numberOfNotifications =
    (notificationsData?.pages.length - 1) * pageSize +
      notificationsData?.pages.slice(-1)[0].length || 0

  useEffect(() => {
    markAllNotificationsAsSeen({ userId: profile?.id })
  }, [])

  return (
    <Box width='27rem' overflow='hidden' bgcolor='#FAFAFA' height='100%'>
      <Flex
        pt={3}
        pb={2}
        pl={3}
        pr={2}
        justifyContent='space-between'
        alignItems='center'
        bgcolor='white'
      >
        <Flex alignItems='center' gap={2}>
          <PageTitle title={localizedStrings.notifications} sx={{ mb: 0 }} />
          {isFetching && <CircularProgress size={26} />}
        </Flex>
        <Flex>
          <DropdownButton
            label={localizedStrings.actions}
            options={[
              {
                title: localizedStrings.readAndUnread,
                onSelect: () => setFilter('ALL'),
                sectionId: 1,
                selected: filter === 'ALL',
                icon: <InboxOutlined />,
              },
              {
                title: localizedStrings.unreadOnly,
                onSelect: () => setFilter('UNREAD'),
                sectionId: 1,
                selected: filter === 'UNREAD',
                icon: <NotificationsOutlined />,
              },
              {
                title: localizedStrings.markAllAsRead,
                onSelect: () =>
                  markAllNotificationsAsRead({ userId: profile.id }),
                sectionId: 2,
                icon: <DoneAllOutlined />,
              },
            ]}
            sections={[
              {
                id: 1,
                title: localizedStrings.filter,
              },
              {
                id: 2,
                title: localizedStrings.actions,
              },
            ]}
            renderButton={(onClick) => (
              <IconButton onClick={onClick}>
                <MoreHorizOutlined />
              </IconButton>
            )}
          />

          <IconButton onClick={hideDrawer}>
            <Clear />
          </IconButton>
        </Flex>
      </Flex>

      <Divider />

      <Box
        overflow='scroll'
        id='scrollableContainer'
        height='calc(100% - 82px)'
      >
        <InfiniteScroll
          dataLength={numberOfNotifications}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={
            <Text mt={1.5} textAlign='center' color='grey'>
              {localizedStrings.loading}
            </Text>
          }
          scrollableTarget='scrollableContainer'
        >
          <TransitionGroup>
            {notifications.map((notification) => (
              <Collapse key={notification.id}>
                <NotificationsListItem notification={notification} />
              </Collapse>
            ))}
            {notifications.length === 0 && (
              <Collapse>
                <Text mt={1.5} textAlign='center' color='grey'>
                  {localizedStrings.youAreUpToDate}
                </Text>
              </Collapse>
            )}
          </TransitionGroup>
        </InfiniteScroll>
      </Box>
    </Box>
  )
}
