import React, { useEffect } from 'react'
import { UseInfiniteQueryResult, useQueryClient } from '@tanstack/react-query'
import produce from 'immer'
import _ from 'lodash'

export const usePageableQuery = <T = unknown>({
  queryEnabled,
  queryKey,
  dataTransformer,
  cursorPage = false,
}) => {
  const queryClient = useQueryClient()

  // We cache only the first 3 pages of the pageableQuery deleting the rest in
  // this effect. This prevents re-fetching large numbers of pages on refresh
  useEffect(() => {
    if (queryEnabled === false) return
    queryClient.setQueryData<UseInfiniteQueryResult<T>['data']>(
      queryKey,
      (data) => {
        return produce(data, (draft) => {
          if (!draft) return
          _.remove(draft.pages, (page, index) => index > 2)
          _.remove(draft.pageParams, (page, index) => index > 2)
        })
      },
    )
  }, [queryEnabled])

  const cacheRef = React.useRef<
    Record<number, { transformedPage: any; page: any }>
  >({})

  const select = React.useCallback(
    ({ pages, pageParams }) => {
      const outputPages = pages.map((page, pageIndex) => {
        if (cacheRef.current[pageIndex]?.page === page) {
          return cacheRef.current[pageIndex].transformedPage
        }

        const transformedPage = dataTransformer(page)
        cacheRef.current[pageIndex] = { transformedPage, page }

        return transformedPage
      })

      return { pageParams, pages: outputPages }
    },
    [dataTransformer],
  )

  return {
    select,
    getNextPageParam: (lastPage) => {
      if (cursorPage) {
        return lastPage.pagination?.nextCursor || undefined
      }
      return lastPage.last ? undefined : lastPage.number + 2
    },
    getPreviousPageParam: (firstPage) => {
      if (cursorPage) {
        return firstPage.pagination?.previousCursor || undefined
      }
      return firstPage.first ? undefined : firstPage.number
    },
  }
}
