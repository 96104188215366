import { localizedStrings } from '@core/strings'
import { FilterValueMap } from '@core/providers/filters'
import React, { useEffect } from 'react'
import { Box, Flex } from '@fundamentals'
import { DropdownButton } from '@common/material'
import Add from '@mui/icons-material/Add'
import { SxProps, Theme } from '@mui/material/styles'
import { FundamentalFilter } from './fundamentals/FundamentalFilter'
import { FilterMapInternal } from '@core/providers/filters'
import { FilterStore } from '@core/providers/filters'

export type FilterProps<T extends FilterMapInternal> = {
  filters: T
  store: FilterStore<T>
  onChange?: (values: FilterValueMap<T>) => void
  sx?: SxProps<Theme>
  noFlex?: boolean
  readOnly?: boolean
  isInModal?: boolean
}

export const Filters: <T extends FilterMapInternal>(
  props: FilterProps<T>,
) => any = ({ filters, onChange, store, sx, noFlex, readOnly, isInModal }) => {
  useEffect(() => {
    onChange && store.addOnChangeListener(onChange)
  }, [])

  const isVisible = (filterKey: string) => !filters[filterKey]?.isHidden

  const addDropdownOptions = store.notVisibleFilterKeys
    .filter(isVisible)
    .map((filterKey) => {
      // Null or false can be passed in to conditionally not render a filter like `operator && { ...filter }`
      if (!filters[filterKey]) {
        return null
      }
      return {
        title: filters[filterKey].label,
        value: filterKey,
        type: filters[filterKey].type,
      }
    })

  const insideFilters = (
    <>
      {store.visibleFilterKeys
        .filter(isVisible)
        .filter((filterKey) => !!filters?.[filterKey])
        .map((filterKey) => {
          return (
            <Box sx={{ mb: 1 }} key={filterKey}>
              <FundamentalFilter
                filter={filters?.[filterKey]}
                store={store}
                storeKey={filterKey}
                readOnly={readOnly || filters?.[filterKey]?.readOnly}
                isInModal={isInModal}
              />
            </Box>
          )
        })}
      {!readOnly && !!store.notVisibleFilterKeys?.length && (
        <DropdownButton
          startIcon={<Add />}
          variant='outlined'
          label={localizedStrings.addFilter}
          options={addDropdownOptions}
          onSelect={(addedFilterKey) => {
            store.addFilter(addedFilterKey)
          }}
          size={'small'}
          sx={{
            py: 0.7,
            alignSelf: 'flex-start',
            '&.MuiButton-root': { minWidth: 'max-content' },
            mb: 1,
          }}
        />
      )}
    </>
  )

  if (noFlex) {
    return insideFilters
  } else {
    return (
      <Flex
        sx={sx}
        alignItems='stretch'
        flexWrap='wrap'
        data-test='list-filters'
      >
        {insideFilters}
      </Flex>
    )
  }
}
