import React from 'react'
import { TooltipSidebar } from 'components/Tooltip'
import { Box, Link, Text } from '@fundamentals'
import { SidebarLinkProps } from './types'
import { styled } from '@mui/material/styles'
import { MenuItem } from '@common/material'

const StyledLink = styled(Link)<{
  active: boolean
  collapse: boolean
  icon: boolean
}>`
  ${({ theme, active }) => {
    return theme.unstable_sx({
      color: active ? 'primary.main' : 'action.active',
      px: 1,
    })
  }}
`

const SidebarLink: React.FC<SidebarLinkProps> = ({
  href,
  icon,
  selectedIcon,
  iconOnly,
  title,
  active,
  onClick,
  ...props
}) => {
  return (
    <TooltipSidebar className='tooltip' title={title} disable={!iconOnly}>
      <StyledLink
        onClick={onClick}
        href={href}
        active={active}
        collapse={iconOnly}
        icon={!!icon}
        data-test={props['data-test']}
      >
        <MenuItem
          sx={{
            width: '100%',
            height: '2.2rem',
            px: !iconOnly ? 0 : '0.5625rem',
          }}
        >
          <Box mr={iconOnly ? 0 : 2}>
            {active && selectedIcon ? selectedIcon : icon}
          </Box>
          {!iconOnly && (
            <Text
              color={active ? 'primary.main' : 'text.primary'}
              fontSize='0.875em'
              // HACK: This is a hack to make text line up with icons, this is due to a problem with fonts
              // TODO: fix fonts and remove this hack
              mt={0.4}
            >
              {title}
            </Text>
          )}
        </MenuItem>
      </StyledLink>
    </TooltipSidebar>
  )
}

export { SidebarLink }
