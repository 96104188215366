import { useEffect, useState } from 'react'
import { Flex, Text } from '@fundamentals'
import { motion, useSpring, useTransform } from 'motion/react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { localizedStrings } from '@core/strings'

type DrawAreaSquareMeterCardProps = {
  squareMeters: number
}

const fadeInSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const AnimatedFlex = styled(Flex)`
  animation: ${fadeInSlideDown} 0.5s ease-out;
  opacity: 1;
`

const AnimatedNumber: React.FC<{ value: number }> = ({ value }) => {
  const spring = useSpring(value, { mass: 0.8, stiffness: 75, damping: 15 })
  const display = useTransform(spring, (current) =>
    Math.round(current).toLocaleString(),
  )
  const [color, setColor] = useState('black') // Default color

  useEffect(() => {
    const previousValue = spring.get() // Get the current value before the update
    const isInitialLoad = previousValue === undefined || previousValue === value

    if (isInitialLoad) {
      // Reset to default color on initial load or no change
      setColor('black')
      return
    }

    const isIncreasing = value > previousValue
    setColor(isIncreasing ? 'green' : 'red') // Set color based on direction

    spring.set(value)

    // Listen for when the spring animation finishes
    spring.on('change', (current) => {
      if (Math.round(current) === value) {
        setColor('black') // Reset to default color when animation completes
      }
    })
  }, [spring, value])

  return <motion.span style={{ color, fontWeight: 500 }}>{display}</motion.span>
}

export const DrawAreaSquareMeterCard: React.FC<
  DrawAreaSquareMeterCardProps
> = ({ squareMeters }) => {
  return (
    <AnimatedFlex
      alignItems={'center'}
      bgcolor={'white'}
      borderRadius={2}
      p={2}
      height={40}
    >
      <Text>
        {localizedStrings.totalDrawnAreaSize}
        <br />
        <AnimatedNumber value={squareMeters} />
        {localizedStrings.m2}
      </Text>
    </AnimatedFlex>
  )
}
