import moment from 'moment'
import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { IAppNotification } from '@core/entities/notifications/IAppNotification'
import {
  AppNotificationAction,
  AppNotificationReferenceObject,
  AppNotificationTargetResult,
  appNotificationTargetResultClassMap,
} from '@core/entities/notifications/types'
import { User } from '@core/entities/user'
import { localizedStrings } from '@core/strings'
import { ProfileOrganisation } from '../profile'

export class AppNotification<
  T extends AppNotificationReferenceObject = AppNotificationReferenceObject,
> extends Entity<IAppNotification<T>> {
  id: number
  userId: number
  isRead: boolean
  createdOn: string

  action: AppNotificationAction
  referenceObjectType: T

  @Type(({ object }) => {
    const notification = object as IAppNotification<T>
    return appNotificationTargetResultClassMap[notification.referenceObjectType]
  })
  targetResult: AppNotificationTargetResult<T>

  @Type(() => User)
  actor?: User

  public static new(payload: unknown): AppNotification {
    const entity = plainToInstance(AppNotification, payload)
    return entity as AppNotification
  }

  /**
   * This method is used to narrow down the type of `targetResult`.
   *
   * @example
   * if (notification.isTargetResultOfType('PERMIT')) {
   *   // Within this block, TS knows that `notification.targetResult` is of type `Permit`
   *   notification.targetResult.permitAuthor;
   * }
   */
  public isTargetResultOfType<U extends AppNotificationReferenceObject>(
    referenceObjectType: U,
  ): this is AppNotification<U> {
    // Cast to `AppNotificationReferenceObject` is needed to allow usage of
    // method in class, i.e. this.isTargetResultOfType('PERMIT')
    return (
      (this.referenceObjectType as AppNotificationReferenceObject) ===
      referenceObjectType
    )
  }

  public getIsTargetResultKnown() {
    return !!appNotificationTargetResultClassMap[this.referenceObjectType]
  }

  public getTitle() {
    const userPostfix = this.actor
      ? ` ${localizedStrings.by.toLowerCase()} ${this.actor.getFullName()}`
      : ''

    return `${getTitleActionText(this)}${userPostfix}`
  }

  public getDescription({
    organisations,
  }: {
    organisations: ProfileOrganisation[]
  }) {
    return getDescriptionText(this, organisations)
  }

  public getFormattedCreatedOn() {
    return moment(this.createdOn).format('DD MMM YYYY HH:mm')
  }
}

const getTitleActionText = (notification: AppNotification): string => {
  switch (notification.action) {
    case 'SENT_TO_PERMIT_HOLDER':
      return localizedStrings.permitAssignedToYou
    case 'REQUESTED':
      return localizedStrings.permitRequested
    case 'ISSUED':
      return localizedStrings.permitApproved
    case 'OPENED':
      return localizedStrings.permitIsNowOpen
    case 'EXTENSION_REQUESTED':
      return localizedStrings.permitExtensionRequested
    case 'EXTENSION_APPROVED':
      return localizedStrings.permitExtensionApprovedNotifications
    case 'EXTENSION_REJECTED':
      return localizedStrings.permitExtensionRejectedNotifications
    case 'DISCARDED':
      return localizedStrings.permitDiscarded
    case 'AWAITING_FOR_FINAL_SIGN_OFF':
      return localizedStrings.finalSignOffRequested
    case 'CLOSED_ADMIN':
    case 'CLOSED_USER':
      return localizedStrings.permitIsNowClosed
    case 'CANCELLED':
      return localizedStrings.permitCancelledNotifications
    case 'COUNTDOWN_FINISHED':
    case 'REMIND_POST_PERMIT_CHECKS':
      return localizedStrings.permitRequiresPostPermitChecks
    case 'EXPIRED':
      return localizedStrings.permitHasExpired
    case 'ABOUT_TO_EXPIRE':
      const endsOn =
        notification.isTargetResultOfType('PERMIT') &&
        notification.targetResult.endsOn
      const expirationDate = new Date(endsOn) // Parse the endsOn date
      const currentDate = new Date(notification.createdOn) // Get the current date and time
      // Calculate the difference in milliseconds
      const timeDifference = expirationDate.getTime() - currentDate.getTime()
      // Convert the difference to minutes
      const minutesUntilExpiration = Math.floor(timeDifference / (1000 * 60))

      return localizedStrings.aboutToExpire(minutesUntilExpiration)
    case 'CHANGES_REQUESTED':
      return localizedStrings.changesRequestedNotifications
    case 'CHANGED_ADDRESSED':
      return localizedStrings.changesAddressedNotifications
    case 'PARTICIPANT_ADDED':
      if (notification.isTargetResultOfType('PERMIT')) {
        return localizedStrings.youHaveBeenAddedToAPermit
      } else if (notification.isTargetResultOfType('CHECKLIST')) {
        return localizedStrings.youHaveBeenAddedToAChecklist
      }
      return ''
    case 'TRANSFER_INITIATED':
      return localizedStrings.permitTransferInitiatedNotifications
    case 'TRANSFER_REQUESTED':
      return localizedStrings.permitTransferRequestedNotifications
    case 'TRANSFER_ACCEPTED':
      return localizedStrings.permitTransferApprovedNotifications
    case 'TRANSFER_REJECTED':
      return localizedStrings.permitTransferRejectedNotifications
    case 'TRANSFER_CANCELLED':
      return localizedStrings.permitTransferCancelledNotifications
    case 'SUSPENDED':
      return localizedStrings.permitSuspendedNotifications
    case 'RESUMED':
      return localizedStrings.permitResumedNotifications
    case 'SUSPENSION_RESUME_REQUESTED':
      return localizedStrings.requestToResumePermit
    case 'SUSPENSION_RESUME_REQUEST_APPROVED':
      return localizedStrings.resumeRequestApproved
    case 'SUSPENSION_RESUME_REQUEST_DECLINED':
      return localizedStrings.requestToResumePermitRejected
    case 'REGISTER_ITEM_CREATED':
      return localizedStrings.registerItemCreatedNotifications
    case 'REGISTER_ITEM_UPDATED':
      return localizedStrings.registerItemUpdatedNotifications
    case 'COMMENT_ADDED':
      return localizedStrings.newComment
    default:
      API.errorTracking.notify(
        new Error(`Unknown notification action: ${notification.action}`),
      )
      // Convert the string to lowercase and replace underscores with spaces
      const lowerCased = notification.action.toLowerCase().replace(/_/g, ' ')

      // Capitalize only the first letter and return the transformed string
      return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1)
  }
}

const getDescriptionText = (
  notification: AppNotification,
  organisations: ProfileOrganisation[],
): string => {
  if (
    notification.isTargetResultOfType('PERMIT') ||
    notification.isTargetResultOfType('CHECKLIST')
  ) {
    return `${notification.targetResult.template.name} - ${notification.targetResult.shortUUID}`
  } else if (notification.isTargetResultOfType('PROJECT')) {
    const orgName = organisations.find(
      (org) => org.id === notification.targetResult.organisationId,
    )?.name
    return `${notification.targetResult.name} - ${orgName}`
  }

  return notification.referenceObjectType
}
