import { IconButton as MaterialButton } from '@mui/material'
import { styled } from '@mui/material/styles'

const IconButton = styled(MaterialButton)`
  ${({ theme }) => {
    return theme.unstable_sx({
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        outline: 'none',
      },
    })
  }}
`

export { IconButton }
