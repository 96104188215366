import React from 'react'
import { Card } from '@common/material'
import {
  AuditResult,
  AuditResultScopeTypes,
} from '@core/entities/audit/AuditResult'
import { localizedStrings } from '@core/strings'
import { RegisterItem } from '@core/entities/register/RegisterItem'
import {
  TimelineEventBlock,
  TimelineEventHeader,
  TimelineLinkBlock,
  TimelineTextBlock,
  TimelineChecklistCommentBlocks,
  TimelineRegisterPropertiesBlocks,
  TimelineGroupPermissionsBlocks,
  TimelinePermitLocationBlock,
  TimelinePermitStatusTrailBlock,
  TimelineMediaBlock,
  TimelineSignatureBlock,
  TimelineBriefedParticipantBlock,
  TimelineMoveToFolderBlock,
  TimelineSiteBoxStatusTrailBlock,
  TimelineSiteBoxDocumentUpdateBlock,
  TimelineProjectUpdatesBlock,
} from '@common/Timeline/blocks'

import { PageUrls } from '@core/page-urls'
import { palette } from '@core/theme/palette'
import { TimelineProjectStatusTrailBlock } from '@common/Timeline/blocks/TimelineProjectStatusTrailBlock'
import { TimelinePermissionAssignmentsBlocks } from '@common/Timeline/blocks/TimelinePermissionAssignmentsBlocks'
import { TimelineUserRoleUpdateBlock } from '@common/Timeline/blocks/TimelineUserRoleUpdateBlock'
import { OrganisationUser, ProjectUser } from '@core/entities/user'
import { TimelineUserBlocks } from '@common/Timeline/blocks/TimelineUserBlocks'
import { TimelineTemplateBlocks } from '@common/Timeline/blocks/TimelineTemplateBlocks'
import { TimelinePermitBlocks } from '@common/Timeline/blocks/TimelinePermitBlocks'
import { Collapsible } from '@common/Collapsible'
import { TimelineMultiLinksBlock } from '@common/Timeline/blocks/TimelineMultiLinksBlock'
import { CardProps } from '@mui/material'

type TimelineItemProps = CardProps & {
  auditResult: AuditResult
  auditResults: Array<AuditResult>
  registerItem?: RegisterItem
  hovered?: boolean
  scope?: AuditResultScopeTypes
}

export const TimelineItem: React.FC<TimelineItemProps> = ({
  auditResult,
  hovered,
  scope,
  auditResults,
  ...props
}) => {
  const showProjectInfo =
    auditResult?.project &&
    !['PERMIT', 'CHECKLIST', 'PROJECT', 'REGISTER_ITEM', 'MAP_MODAL'].includes(
      scope,
    )
  const showChecklistInfo = auditResult.checklist && scope !== 'CHECKLIST'
  const showPermitInfo =
    !!auditResult.permit && !['PERMIT', 'MAP_MODAL'].includes(scope)
  const showRegisterInfo = !!auditResult.register && scope !== 'REGISTER_ITEM'

  const showAppliedToGroups =
    !!auditResult.appliedToGroups && scope !== 'AUTH_GROUP'
  return (
    <Card
      sx={{
        p: 2,
        mb: 2,
        outline: `${hovered ? palette.primary?.main : 'transparent'} solid 1px`,
        borderColor: hovered && palette.primary?.main,
        transition: 'outline 0.2s ease',
      }}
      {...props}
    >
      <TimelineEventHeader auditResult={auditResult} />
      <TimelineEventBlock auditResult={auditResult} />
      {showProjectInfo && (
        <TimelineLinkBlock
          title={localizedStrings.project}
          text={auditResult.project.name}
          href={PageUrls.project(
            auditResult.organisation?.id,
            auditResult.project.id,
          )}
        />
      )}
      {showChecklistInfo && (
        <TimelineLinkBlock
          title={localizedStrings.checklist}
          href={
            scope === 'PROJECT'
              ? PageUrls.projectChecklistDetail({
                  organisationId: auditResult.organisation?.id,
                  projectId: auditResult.project?.id,
                  checklistId: auditResult.checklist.id,
                })
              : PageUrls.checklist(
                  auditResult.organisation?.id,
                  auditResult.checklist.id,
                )
          }
          text={auditResult.checklist.templateName}
        />
      )}
      {showPermitInfo && (
        <TimelineLinkBlock
          title={localizedStrings.permit}
          text={`${auditResult.permit.templateName} - ${auditResult.permit.shortUUID}`}
          href={
            scope === 'PROJECT'
              ? PageUrls.projectPermitDetail({
                  organisationId: auditResult.organisation?.id,
                  projectId: auditResult.project?.id,
                  permitId: auditResult.permit.id,
                })
              : PageUrls.permit(
                  auditResult.organisation?.id,
                  auditResult.permit.id,
                )
          }
        />
      )}
      {auditResult.siteboxDocument && (
        <TimelineLinkBlock
          title={localizedStrings.siteboxDocument}
          text={auditResult.siteboxDocument.name}
          href={PageUrls.projectSiteBoxDocument({
            organisationId: auditResult.organisation?.id,
            projectId: auditResult.project?.id,
            documentId: auditResult.siteboxDocument.id,
            folderId: auditResult.metadata.movedToFolder?.id || null,
          })}
        />
      )}
      {auditResult.siteboxDocumentVersion && (
        <TimelineTextBlock
          title={localizedStrings.siteBoxDocumentVersion}
          text={`Version ${auditResult.siteboxDocumentVersion.documentVersion}`}
        />
      )}
      <TimelineUserBlocks auditResult={auditResult} />
      <TimelinePermitBlocks auditResult={auditResult} />
      {auditResult.isBriefingParticipant() && (
        <TimelineBriefedParticipantBlock auditResult={auditResult} />
      )}
      {auditResult.hasComment() && (
        <TimelineChecklistCommentBlocks auditResult={auditResult} />
      )}
      {showRegisterInfo && (
        <TimelineTextBlock
          title={localizedStrings.register}
          text={auditResult.register.name}
        />
      )}
      {auditResult.hasInspection() && (
        <TimelineLinkBlock
          title={localizedStrings.checklistInformation}
          href={PageUrls.projectChecklistDetail({
            organisationId: auditResult.organisation?.id,
            projectId: auditResult.project?.id,
            checklistId: auditResult.metadata.inspectionChecklist.checklistId,
          })}
          text={auditResult.metadata.inspectionChecklist.templateName}
        />
      )}
      {auditResult.group && scope !== 'AUTH_GROUP' && (
        <TimelineLinkBlock
          title={localizedStrings.authorisationGroup}
          href={
            auditResult.project
              ? PageUrls.projectAuthorisationGroup({
                  organisationId: auditResult.organisation?.id,
                  projectId: auditResult.project?.id,
                  groupId: auditResult.group?.id,
                })
              : PageUrls.organisationAuthorisationGroup({
                  organisationId: auditResult.organisation?.id,
                  groupId: auditResult.group.id,
                })
          }
          text={auditResult.group.name}
        />
      )}
      {showAppliedToGroups && (
        <TimelineMultiLinksBlock
          title={`${localizedStrings.authorisationGroup}${
            auditResult.appliedToGroups.length > 1 ? 's' : ''
          }`}
          links={auditResult.appliedToGroups.map((group) => ({
            href: auditResult.project
              ? PageUrls.projectAuthorisationGroup({
                  organisationId: auditResult.organisation?.id,
                  projectId: auditResult.project?.id,
                  groupId: group.id,
                })
              : PageUrls.organisationAuthorisationGroup({
                  organisationId: auditResult.organisation?.id,
                  groupId: group.id,
                }),
            text: group.name,
          }))}
        />
      )}
      {/*We hide the info here when auditResult.hasComment(). The Comment block will render the info with comment styles*/}
      {!auditResult.hasComment() && auditResult.info && (
        <TimelineTextBlock
          title={auditResult.getInfoTitle()}
          text={auditResult.info}
        />
      )}
      {auditResult.hasTemplateInformation() && (
        <TimelineTemplateBlocks auditResult={auditResult} />
      )}
      {!!auditResult.isUpdatingRegisterItem() && (
        <TimelineRegisterPropertiesBlocks auditResult={auditResult} />
      )}
      <Collapsible
        label={{
          collapsed: localizedStrings.showMore,
          open: localizedStrings.hide,
        }}
        buttonProps={{
          size: 'small',
          sx: { mt: 1, mb: -0.5 },
        }}
        collapsed
        hidden={scope === 'MAP_MODAL'}
      >
        {auditResult.isUpdatingProjectDetails() && (
          <TimelineProjectUpdatesBlock
            projectDataAfter={auditResult.metadata.projectDataAfter}
            projectDataBefore={auditResult.metadata.projectDataBefore}
          />
        )}
        {auditResult.isUpdatingSiteBoxDocumentStatus() && (
          <TimelineSiteBoxStatusTrailBlock
            preSiteBoxDocumentValuesData={
              auditResult.metadata.preSiteBoxDocumentValuesData
            }
            postSiteBoxDocumentValuesData={
              auditResult.metadata.postSiteBoxDocumentValuesData
            }
          />
        )}
        {auditResult.isRenamingSiteBoxDocument() && (
          <TimelineSiteBoxDocumentUpdateBlock
            preSiteBoxDocumentValuesData={
              auditResult.metadata.preSiteBoxDocumentValuesData
            }
            postSiteBoxDocumentValuesData={
              auditResult.metadata.postSiteBoxDocumentValuesData
            }
          />
        )}
        {auditResult.metadata?.movedToFolder && (
          <TimelineMoveToFolderBlock auditResult={auditResult} />
        )}
        {auditResult.isUpdatingTemplatePermissionGroups() && (
          <TimelinePermissionAssignmentsBlocks auditResult={auditResult} />
        )}
        {auditResult.isUpdatingProjectMemberRole() && (
          <TimelineUserRoleUpdateBlock
            preValues={auditResult.metadata.preProjectMemberData.roles.map(
              (role) => ProjectUser.getRoleString(role),
            )}
            postValues={auditResult.metadata.postProjectMemberData.roles.map(
              (role) => ProjectUser.getRoleString(role),
            )}
          />
        )}
        {auditResult.isUpdatingOrganisationMemberRole() && (
          <TimelineUserRoleUpdateBlock
            preValues={auditResult.metadata.preOrgMemberData.roles.map((role) =>
              OrganisationUser.roleString(role),
            )}
            postValues={auditResult.metadata.postOrgMemberData.roles.map(
              (role) => OrganisationUser.roleString(role),
            )}
          />
        )}
        {auditResult.metadata?.updateNote && (
          <TimelineTextBlock
            title={localizedStrings.info}
            text={auditResult.metadata.updateNote}
          />
        )}
        {auditResult.isUpdatingGroupPermissions() && (
          <TimelineGroupPermissionsBlocks
            permissionsUpdatedData={auditResult.metadata.permissionsUpdatedData}
            scope={scope}
            organisationId={auditResult.organisation?.id}
            projectId={auditResult.project?.id}
          />
        )}
        {auditResult.hasPermitStatusChange() && (
          <TimelinePermitStatusTrailBlock
            permitStatusBefore={auditResult.metadata.permitStatusBefore}
            permitStatusAfter={auditResult.metadata.permitStatusAfter}
          />
        )}
        {auditResult.hasProjectStatusChange() && (
          <TimelineProjectStatusTrailBlock
            projectStatusBefore={auditResult.metadata.projectStatusBefore}
            projectStatusAfter={auditResult.metadata.projectStatusAfter}
          />
        )}
        {auditResult.getCoordinates() && (
          <TimelinePermitLocationBlock
            auditResult={auditResult}
            auditResults={auditResults}
          />
        )}
        {auditResult.hasProofs() && (
          <TimelineMediaBlock auditResult={auditResult} />
        )}
        {auditResult.signatureUrl && (
          <TimelineSignatureBlock auditResult={auditResult} />
        )}
      </Collapsible>
    </Card>
  )
}
