import { localizedStrings } from '@core/strings'
import { Box, BoxProps, Flex, Text } from '@fundamentals'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { Checkbox } from './material'
import Tooltip from '../../../components/Tooltip'
import _ from 'lodash'
import { FormHelperText } from '@mui/material'

type ValueType = string | number | boolean

type MultipleChoiceOptionType = {
  label: string
  value: ValueType
  select?: (value: ValueType) => void
  description?: string
  tooltipText?: string
  footer?: React.ReactNode
}

type MultipleChoiceProps = BoxProps & {
  title?: string
  options: Array<MultipleChoiceOptionType>
  selected: ValueType[]
  onSelect: (value: ValueType[]) => void
  type?: string
  multiSelect?: boolean
  disabled?: boolean
  showCheckbox?: boolean
  allowUnselect?: boolean
  dataTest?: string
  highlightSelectedText?: boolean
  maxSelect?: number
  error?: string
}

type DefaultOptions = { [K in 'boolean']: MultipleChoiceOptionType[] }

const StyledMultipleChoiceItem = styled(Flex)<{
  selected?: boolean
  disabled?: boolean
  type?: string
}>`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 192px;
  ${({ type, theme }) => {
    if (type === 'input')
      return { backgroundColor: theme.palette.legacy.white.ghost }
  }}
  ${({ theme, selected, disabled }) => {
    return theme.unstable_sx({
      border: '1px solid',
      borderRadius: '6px',
      borderColor: selected
        ? disabled
          ? 'primary.light'
          : 'primary.main'
        : '#a4b5d2',
    })
  }}
  &:hover {
    ${({ theme, disabled }) =>
      !disabled &&
      theme.unstable_sx({
        borderColor: 'primary.main',
        cursor: 'pointer',
      })}
`

const MultipleChoice: React.FC<MultipleChoiceProps> & {
  TemplateOptions: DefaultOptions
} = ({
  title,
  options,
  selected,
  showCheckbox,
  onSelect,
  disabled,
  multiSelect,
  maxSelect,
  type,
  allowUnselect,
  dataTest,
  highlightSelectedText,
  error,
  ...props
}) => {
  const [lastSelected, setLastSelected] = useState<ValueType>()
  const renderMultipleChoiceItem = (
    { label, value, select, description, tooltipText, footer },
    index,
  ) => {
    const isItemSelected = selected.includes(value)
    const selectionLimitReached =
      !isItemSelected && maxSelect !== undefined && selected.length >= maxSelect
    return (
      <Box
        mt={!index ? 0 : 2}
        key={index}
        color={
          highlightSelectedText && isItemSelected
            ? 'legacy.primary.10'
            : 'legacy.blue.oxford.7'
        }
      >
        <StyledMultipleChoiceItem
          key={value}
          onClick={() => {
            if (!disabled) {
              select && !selectionLimitReached && select(value)
              if (multiSelect) {
                if (!isItemSelected) setLastSelected(value)
                onSelect(
                  _.xor(
                    selectionLimitReached ? [value, lastSelected] : [value],
                    selected,
                  ),
                )
              } else if (!isItemSelected || allowUnselect) {
                onSelect(_.difference([value], selected))
              }
            }
          }}
          selected={isItemSelected}
          disabled={disabled}
          type={type}
          data-test={`${dataTest}-${value}`}
          px={'0.5rem'}
        >
          <Box>
            <Flex alignItems='center'>
              <Text mr='1'>{label}</Text>
              {tooltipText && (
                <Tooltip place='left' className='tooltip'>
                  {tooltipText}
                </Tooltip>
              )}
            </Flex>

            {description && (
              <Text color='grey' fontSize='15px'>
                {description}
              </Text>
            )}
          </Box>

          {showCheckbox && (
            <Checkbox
              checked={isItemSelected}
              disabled={disabled}
              sx={{ pr: 0 }}
            />
          )}
        </StyledMultipleChoiceItem>
        {footer}
      </Box>
    )
  }

  return (
    <Box {...props} data-test={dataTest}>
      {title && (
        <Text
          fontSize='15px'
          fontWeight='bold'
          color='legacy.blue.oxford7'
          mb={1}
        >
          {title}
        </Text>
      )}
      {options.map(renderMultipleChoiceItem)}
      {error && (
        <FormHelperText sx={{ mt: -0.5 }} error>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}

MultipleChoice.TemplateOptions = {
  boolean: [
    { label: localizedStrings.yes, value: true },
    { label: localizedStrings.no, value: false },
  ],
}

export { MultipleChoice }
