import {
  Tabs as MaterialTabs,
  Tab as MaterialTab,
  TabsProps as MuiTabsProps,
} from '@mui/material'

type TabsProps = MuiTabsProps & {
  component?: React.ElementType<any>
  disabled?: boolean
}
const Tabs: React.FC<TabsProps> = MaterialTabs

const Tab = MaterialTab

export { Tabs, Tab }
