import { PermitTemplate } from '@core/entities/permit/PermitTemplate'
import { ChangeRequest } from '@core/entities/permit/ChangeRequest'
import { PermitApprovalReviews } from '@core/entities/permit/PermitApprovalReviews'
import { TemplateProjectLocations } from '@core/entities/template/TemplateProjectLocations'
import { Entity } from '@core/entities/entity'
import { IPermitBase } from '@core/entities/permit/PermitBase'
import { plainToInstance, Type } from 'class-transformer'
import moment from 'moment/moment'
import {
  IPermitApprovalWrapper,
  PermitApprovalWrapper,
} from '@core/entities/permit/PermitApprovalWrapper'
import { PermitNominateApprover } from '@core/entities/permit/PermitNominateApprover'
import { PermitTransfer } from '@core/entities/permit/PermitTransfer'
import { localizedStrings } from '@core/strings'
import { User } from '@core/entities/user'
import { Participant } from '@core/entities/participant/Participant'
import { FeatureCollection, Feature, GeoJSON, Point, Polygon } from 'geojson'
import { PermitFinalSignOffReviews } from '../PermitFinalSignOffReviews'
import { PermitFinalSignOffReview } from '../PermitFinalSignOffReview'
import { PermitStatus } from './types'
import {
  getGeolocationAreas,
  getW3wLocation,
} from '@core/utils/geolocation-helpers'
import { Comments } from '@core/entities/comments'

export class PermitBase<
  ExtendedType extends IPermitBase = IPermitBase,
> extends Entity<ExtendedType> {
  id: number
  name: string
  uuid: string
  shortUUID: string
  project: { id: number; name: string; timezone: string }
  @Type(() => PermitTemplate)
  template: PermitTemplate
  organisationId: number
  location: string
  locationId?: string
  geoJSON?: GeoJSON
  summary: string
  status: PermitStatus
  startsOn: string
  endsOn: string
  @Type(() => User)
  permitHolder: User
  @Type(() => User)
  permitAuthor: User
  @Type(() => Participant)
  participants: Array<Participant>
  countdownExpiry?: string
  countdownName?: string
  @Type(() => ChangeRequest)
  lastRequestedChange?: ChangeRequest
  @Type(() => PermitTransfer)
  lastTransfer?: PermitTransfer
  projectCounterCode?: string
  @Type(() => PermitApprovalReviews)
  approvalReviews?: PermitApprovalReviews
  @Type(() => PermitFinalSignOffReviews)
  finalSignOffReviews: PermitFinalSignOffReviews
  @Type(() => TemplateProjectLocations)
  projectLocations: TemplateProjectLocations

  //needed for the form to work - API sync would be nice
  projectId?: number
  projectName?: string
  templateId?: number
  templateVersionId?: number
  draftId?: number
  draftName?: string
  permitHolderId?: number
  exportResult?: {
    url: string
    directUrl: string
    key: string
  }
  comments: Comments

  // NOTE: currentTemplate is only present in the outdated permit drafts and should
  // be moved to PermitDraft entity. Added here because react query hooks for fetching
  // drafts are using this entity instead of PermitDraft
  @Type(() => PermitTemplate)
  currentTemplate?: PermitTemplate

  public static new(data: unknown): PermitBase {
    const entity = plainToInstance<PermitBase, unknown>(PermitBase, data)

    return entity
  }

  public getDuration(): string {
    const duration = moment.duration(moment(this.endsOn).diff(this.startsOn))

    const diffYears = duration.years()
    const diffMonths = duration.months()
    const diffDays = duration.days()
    const diffHours = duration.hours()
    const diffMins = duration.minutes()

    const parts = []
    if (diffYears) {
      parts.push(`${diffYears}${localizedStrings.yearsShort}`)
    }
    if (diffMonths) {
      parts.push(`${diffMonths}${localizedStrings.monthsShort}`)
    }
    if (diffDays) {
      parts.push(`${diffDays}${localizedStrings.daysShort}`)
    }
    if (diffHours) {
      parts.push(`${diffHours}${localizedStrings.hoursShort}`)
    }
    if (diffMins) {
      parts.push(`${diffMins}${localizedStrings.minsShort}`)
    }

    const durationString = parts.join(' ')

    return durationString
  }

  public isPermitCountdownActive(): boolean {
    return !!this.countdownExpiry && moment().isBefore(this.countdownExpiry)
  }

  public isAwaitingChanges(): boolean {
    return (
      this.status === 'REQUESTED' &&
      this.lastRequestedChange?.requestedAt &&
      !this.lastRequestedChange.addressedBy
    )
  }

  public isAwaitingChangesFromPermitHolder(): boolean {
    return (
      this.template.initiatorRole === 'PERMIT_HOLDER' &&
      this.approvalReviews.nextApprovalStage === 'AFTER_PERMIT_HOLDER'
    )
  }

  public isAwaitingChangesFromAuthPersonBeforePermitHolder(): boolean {
    return (
      this.template.initiatorRole === 'AUTHORISED_PERSON' &&
      this.approvalReviews.nextApprovalStage === 'BEFORE_PERMIT_HOLDER'
    )
  }

  public isAwaitingChangesFromAuthPersonAfterPermitHolder(): boolean {
    return (
      this.template.initiatorRole === 'AUTHORISED_PERSON' &&
      this.approvalReviews.nextApprovalStage === 'AFTER_PERMIT_HOLDER'
    )
  }

  public getRemainingCountdown(): string {
    if (!this.countdownExpiry) return
    let newCountdownString = ''
    const duration = moment.duration(
      moment(this.countdownExpiry).diff(moment()),
    )

    if (duration.days()) {
      newCountdownString += `${duration.days()}${localizedStrings.daysShort} `
    }
    if (duration.hours()) {
      newCountdownString += `${duration.hours()}${localizedStrings.hoursShort} `
    }
    if (duration.minutes()) {
      newCountdownString += `${duration.minutes()}${
        localizedStrings.minsShort
      } `
    }
    if (duration.seconds()) {
      newCountdownString += `${duration.seconds()}${
        localizedStrings.secondsShort
      }`
    }

    return newCountdownString
  }

  public getNextApproval(): PermitApprovalWrapper {
    const { nextApprovalId, approvals } = this.approvalReviews || {}
    return approvals.find(({ approval }) => approval.id === nextApprovalId)
  }

  public getNextFinalSignOff(): PermitFinalSignOffReview {
    const { nextFinalSignOffId, reviews } = this.finalSignOffReviews || {}
    return reviews?.find(
      ({ finalSignOff }) => finalSignOff.id === nextFinalSignOffId,
    )
  }

  public getNextNominatedApprovers(): Array<PermitNominateApprover> {
    const nextNominatedApprovers =
      this?.approvalReviews?.nominatedApprovers?.approvers?.filter(
        ({ targetApproval }) => {
          return targetApproval.id === this.approvalReviews.nextApprovalId
        },
      ) ?? []

    return nextNominatedApprovers
  }

  public getReviews(
    action?: IPermitApprovalWrapper['action'],
  ): Array<PermitApprovalWrapper> {
    return (
      this.approvalReviews?.approvals.filter(
        (approval) =>
          !!approval.action && (!action || approval.action === action),
      ) || []
    )
  }

  public getStatusChipColor() {
    return PermitBase.getStatusChipColor(this.status)
  }

  public static getStatusChipColor(status: PermitStatus) {
    switch (status) {
      case 'REQUESTED':
      case 'AWAITING_FINAL_SIGN_OFF':
      case 'AWAITING_CHECKS':
      case 'SENT_TO_PERMIT_HOLDER':
      case 'SUSPENDED_RESUME_REQUESTED':
        return 'warning'
      case 'ISSUED':
        return 'info'
      case 'CANCELLED':
      case 'UNSIGNED':
      case 'DISCARDED':
      case 'EXPIRED':
      case 'REJECTED':
      case 'SUSPENDED':
        return 'error'
      case 'OPEN':
      case 'EXTENSION_REQUESTED':
      case 'SIGNED':
        return 'success'
      default:
        return 'primary'
    }
  }

  public getStatusString(): string {
    return PermitBase.getStatusString(this.status)
  }

  public static getStatusString(status: PermitStatus): string {
    switch (status) {
      case 'REQUESTED':
        // We have mapped the Requested state to Awaiting Approval on the front-end
        // This will be renamed in the back-end in the future
        return localizedStrings.awaitingApproval
      case 'REJECTED':
        return localizedStrings.rejected
      case 'DISCARDED':
        return localizedStrings.discarded
      case 'ISSUED':
        return localizedStrings.preStart
      case 'EXPIRED':
        return localizedStrings.expired
      case 'AWAITING_CHECKS':
        return localizedStrings.awaitingPostPermitChecks
      case 'AWAITING_FINAL_SIGN_OFF':
        return localizedStrings.awaitingFinalSignOff
      case 'CLOSED':
        return localizedStrings.closed
      case 'UNSIGNED':
        return localizedStrings.unsigned
      case 'SIGNED':
        return localizedStrings.signed
      case 'EXTENSION_REQUESTED':
        return localizedStrings.requestedExtension
      case 'CANCELLED':
        return localizedStrings.cancelled
      case 'OPEN':
        return localizedStrings.open
      case 'SENT_TO_PERMIT_HOLDER':
        return localizedStrings.sentToPermitHolder
      case 'SUSPENDED':
        return localizedStrings.suspended
      case 'SUSPENDED_RESUME_REQUESTED':
        return localizedStrings.resumeRequested
      default:
        return status
    }
  }

  public getSecondaryStatusString(): string {
    if (
      this.lastTransfer?.status === 'PENDING' ||
      this.lastTransfer?.status === 'AWAITING_REVIEW'
    ) {
      return this.lastTransfer?.statusText()
    }
    if (this.status !== 'REQUESTED') {
      if (
        this.getNextFinalSignOff() &&
        this.finalSignOffReviews.reviews.length > 1
      ) {
        return this.getNextFinalSignOff().finalSignOff.label
      }
    } else {
      if (this.lastRequestedChange) {
        return this.isAwaitingChanges()
          ? localizedStrings.changesRequested
          : localizedStrings.changesDone
      }
      if (this.approvalReviews.hasNominatedApprovers()) {
        return this.getNextNominatedApprovers()
          ?.map(({ targetApprovers }) => {
            return targetApprovers.map((approver) => approver.name).join(', ')
          })
          .join(', ')
      }
      if (this.approvalReviews.approvals.length > 1) {
        return this.getNextApproval().approval.label
      }
    }
  }

  public getSecondaryStatusOptions(): {
    label: string
    completed: boolean
  }[] {
    if (this.approvalReviews.approvals.length > 1) {
      return this.approvalReviews
        .getApprovalsFromCurrentStage()
        .map((approval) => {
          const approvalId = approval.approval.id
          const nominatedApprovers =
            this.approvalReviews.nominatedApprovers?.approvers.filter(
              ({ targetApproval }) => targetApproval.id === approvalId,
            ) ?? []

          const labelPostfix = nominatedApprovers
            .map(({ targetApprovers }) =>
              targetApprovers.map((approver) => approver.name),
            )
            .flat()
            .join(', ')

          return {
            label:
              approval.submittedBy?.getFullName() ||
              `${approval.approval.label}${
                labelPostfix ? ` - ${labelPostfix}` : ''
              }`,
            completed: approval.action === 'APPROVED',
          }
        })
    }
    if (this.finalSignOffReviews.reviews?.length > 1) {
      return this.finalSignOffReviews.reviews.map((review) => ({
        label: review.finalSignOff.label,
        completed: review.finalSignOff?.finalSignOffAnswers?.isAnswered(),
      }))
    }
  }

  getW3wLocation(): Feature<Point> {
    return getW3wLocation(this.geoJSON)
  }

  getGeolocationAreas(): FeatureCollection<Polygon> {
    return getGeolocationAreas(this.geoJSON)
  }

  public getProjectLocationsAreasString(): string {
    const selectedAreasNames = this?.projectLocations?.areas?.flatMap((area) =>
      area.selectedAreas?.map((selectedArea) => selectedArea.name),
    )
    const projectLocationsAreasString = selectedAreasNames
      ?.map((name, index) =>
        index !== selectedAreasNames.length - 1 ? name + ',' : name,
      )
      ?.join(' ')

    return projectLocationsAreasString
  }

  public isApprovalDraftsEnabled(): boolean {
    return this.template?.currentVersion?.allowApprovalDrafts
  }
}
