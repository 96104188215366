import React from 'react'
import { Flex } from '@fundamentals'
import { Chip } from '@common/material'
import { localizedStrings } from '@core/strings'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'
import { SiteBoxDocument } from '@core/entities/SiteBoxDocument'

type ComponentProps = {
  preSiteBoxDocumentValuesData: Partial<SiteBoxDocument>
  postSiteBoxDocumentValuesData: Partial<SiteBoxDocument>
}

export const TimelineSiteBoxStatusTrailBlock: React.FC<ComponentProps> = ({
  preSiteBoxDocumentValuesData,
  postSiteBoxDocumentValuesData,
}) => {
  return (
    <TimelineBlockBase title={localizedStrings.statusTrail}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Chip
          size='small'
          color={preSiteBoxDocumentValuesData.getStatusColor()}
          label={preSiteBoxDocumentValuesData.getStatusText()}
        />
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Chip
          size='small'
          color={postSiteBoxDocumentValuesData.getStatusColor()}
          label={postSiteBoxDocumentValuesData.getStatusText()}
        />
      </Flex>
    </TimelineBlockBase>
  )
}
