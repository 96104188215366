import React from 'react'
import { match } from 'ts-pattern'
import { localizedStrings } from '@core/strings'
import { Flex } from '@fundamentals'
import { Button } from '@common/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FormatShapesIcon from '@mui/icons-material/FormatShapes'
import { DrawAreaSquareMeterCard } from './DrawAreaSquareMeterCard'

type DrawControlToolbarProps = {
  onDrawNewArea: () => void
  onDeleteArea: () => void
  onCloseArea: () => void
  onSaveAreas: () => void
  onConfirmAreas: () => void
  featureCount: number
  squareMeters: number
  canDelete: boolean
  mode: 'draw' | 'close' | 'confirm'
}

export const DrawControlToolbar: React.FC<DrawControlToolbarProps> = ({
  mode,
  onDrawNewArea,
  onDeleteArea,
  onCloseArea,
  onSaveAreas,
  onConfirmAreas,
  squareMeters,
  featureCount,
  canDelete,
}) => {
  return (
    <Flex flex={1} alignItems='center' justifyContent='space-between' pt={2}>
      <Flex mr={3}>
        {match(mode)
          .with('close', () => (
            <Button
              sx={{ mr: 3 }}
              startIcon={<FormatShapesIcon />}
              variant='outlined'
              size='large'
              onClick={onCloseArea}
            >
              {localizedStrings.closeShape}
            </Button>
          ))
          .otherwise(() => (
            <Button
              sx={{ mr: 3 }}
              startIcon={<AddIcon />}
              variant='outlined'
              size='large'
              onClick={onDrawNewArea}
            >
              {localizedStrings.drawNewArea}
            </Button>
          ))}

        {canDelete && (
          <Button
            startIcon={<DeleteOutlineIcon />}
            color='error'
            variant='outlined'
            size='large'
            onClick={onDeleteArea}
          >
            {localizedStrings.delete}
          </Button>
        )}
      </Flex>

      <Flex alignItems='center'>
        {!!squareMeters && (
          <DrawAreaSquareMeterCard squareMeters={squareMeters} />
        )}

        {match(mode)
          .with('confirm', () => (
            <Button
              variant={'contained'}
              onClick={onConfirmAreas}
              size='large'
              disabled={!featureCount}
            >
              {localizedStrings.confirmAreas(featureCount)}
            </Button>
          ))
          .otherwise(() => (
            <Button
              variant={'contained'}
              onClick={onSaveAreas}
              size='large'
              disabled={!featureCount || mode === 'close'}
              sx={{ maxHeight: 130 }}
            >
              {featureCount
                ? localizedStrings.saveAreas(featureCount)
                : localizedStrings.save}
            </Button>
          ))}
      </Flex>
    </Flex>
  )
}
