import { localizedStrings } from '@core/strings'
import React, { useEffect } from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { Menu, MenuItem, Button } from '@mui/material'
import {
  DropdownButtonOption,
  DropdownButtonOptionWithSection,
  DropdownButtonProps,
} from './types'
import { styled, useTheme } from '@mui/material/styles'
import { Tooltip } from '@mui/material'
import { Close, CheckOutlined } from '@mui/icons-material'

const StyledMenu = styled(Menu)<{ buttonWidth: number }>`
  & .MuiPaper-root {
    ${({ theme }) =>
      theme.unstable_sx({
        mt: 0.5,
        '.MuiMenuItem-gutters': { px: 1 },
      })}
    ${({ buttonWidth }) => buttonWidth && { minWidth: buttonWidth }};
  }
`

const StyledButton = styled(Button)<{ collapsed: boolean }>`
  .MuiSvgIcon-root {
    font-size: 24px;
  }
  ${({ theme, collapsed }) => {
    return (
      collapsed &&
      theme.unstable_sx({
        '&.MuiButton-root': {
          justifyContent: 'center',
          padding: '0.5625rem',
          minWidth: '2.625rem',
          maxWidth: '2.625rem',
        },
        '.MuiButton-startIcon, .MuiButton-endIcon': { mx: 0 },
      })
    )
  }}
`
export const DropdownButton: React.FC<DropdownButtonProps> = ({
  label,
  options,
  onSelect,
  fullWidth,
  denseOptions,
  collapsed,
  renderButton,
  withCloseOption,
  disableCloseOnSelect = false,
  onBlur,
  sections,
  ...props
}) => {
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>()
  const [buttonWidth, setButtonWidth] = React.useState(0)
  const theme: any = useTheme()
  const anchorOffsetWidth = anchorElement?.offsetWidth || buttonWidth
  useEffect(() => {
    setButtonWidth(anchorOffsetWidth)
  }, [anchorOffsetWidth])

  const open = Boolean(anchorElement)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    setAnchorElement(null)
    onBlur?.()
  }

  const optionsHaveIcons = options?.some((option) => option.icon)

  const hasSelectedOption = options?.some((option) => option.selected)

  // Process options based on sections
  const processedOptions = sections
    ? // Group options by section when sections exist
      sections.map((section) => ({
        sectionTitle: section.title,
        options: options.filter(
          (option: DropdownButtonOptionWithSection) =>
            option.sectionId === section.id,
        ),
      }))
    : // Return all options when sections do not exist
      [{ sectionTitle: null, options }]

  // Unified render function for both sections and options
  const renderOptions = (filteredOptions: DropdownButtonOption[]) =>
    filteredOptions.map(
      (
        {
          title,
          icon,
          value,
          onSelect: onItemSelect,
          color,
          tooltipTitle,
          selected,
          ...option
        },
        index,
      ) => (
        <Tooltip key={index} title={tooltipTitle || ''}>
          <MenuItem
            onClick={(e) => {
              if (onItemSelect) {
                onItemSelect(value)
                onBlur?.()
              }
              if (onSelect) {
                onSelect(value)
                onBlur?.()
              }
              if (!disableCloseOnSelect) handleClose(e)
            }}
            dense={denseOptions}
            sx={{
              color: color,
              '.MuiSvgIcon-root': { color: color || 'grey.600' },
              display: 'flex',
              justifyContent: 'space-between',
            }}
            {...option}
          >
            <Flex alignItems='center' ml={sections && 1}>
              {icon}
              <Text ml={icon && 1} data-test={`dropdown-option-${index}`}>
                {title}
              </Text>
            </Flex>
            {hasSelectedOption && (
              <CheckOutlined
                sx={{
                  opacity: selected ? 1 : 0,
                  ml: 3,
                  color: `${theme.palette.primary.main}!important`,
                }}
              />
            )}
          </MenuItem>
        </Tooltip>
      ),
    )

  return (
    <>
      <Tooltip title={label} disableHoverListener={!collapsed}>
        {renderButton ? (
          renderButton(handleClick)
        ) : (
          <StyledButton
            data-test='dropdown-btn'
            onClick={handleClick}
            fullWidth={fullWidth}
            collapsed={collapsed}
            {...props}
          >
            {!collapsed && label}
          </StyledButton>
        )}
      </Tooltip>

      {open && (
        <StyledMenu
          open={open}
          anchorEl={anchorElement}
          onClose={handleClose}
          buttonWidth={fullWidth && buttonWidth}
        >
          {processedOptions.map(({ sectionTitle, options }, sectionIndex) => (
            <Box key={sectionIndex}>
              {sectionTitle && (
                <Text
                  variant='subtitle2'
                  color='text.secondary'
                  sx={{ px: 2, py: 1 }}
                >
                  {sectionTitle}
                </Text>
              )}
              {renderOptions(options)}
            </Box>
          ))}

          {withCloseOption && (
            <MenuItem key={'close'} onClick={handleClose} dense={denseOptions}>
              {optionsHaveIcons && <Close />}
              <Text
                ml={optionsHaveIcons ? 1 : 0}
                data-test={`dropdown-option-close`}
              >
                {localizedStrings.close}
              </Text>
            </MenuItem>
          )}
        </StyledMenu>
      )}
    </>
  )
}
