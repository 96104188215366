import { FeatureCollection, Point } from 'geojson'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { w3wKeys, RequestTypes } from '@core/react-query/features/w3w'

export const useGetCoordsToW3W = (
  payload: RequestTypes['getCoordsToW3W'],
  options?: UseQueryOptions<FeatureCollection<Point>, string>,
) => {
  return useQuery({
    ...w3wKeys.getCoordsToW3W(payload),
    retry: 0,
    ...options,
  })
}
