import React from 'react'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { Box, Flex, Text } from '@fundamentals'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'
import { localizedStrings } from '@core/strings'
import { Folder as FolderIcon } from '@mui/icons-material'
import { PageUrls } from '@core/page-urls'
import { Folder } from '@core/entities/folders/Folder'
import { Link } from '@common/material'
import moment from 'moment'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineMoveToFolderBlock: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const movedToFolder = auditResult.metadata?.movedToFolder
  const momovedFromFolder = auditResult.metadata?.movedFromFolder

  const getFolderUrl = (folder: Folder) => {
    switch (folder.type) {
      case 'PERMIT_TEMPLATE':
        return PageUrls.organisationPermitTemplates({
          organisationId: folder.organisationId,
          query: {
            folderId: folder.displayName !== 'ROOTS' && folder.id,
          },
        })
      case 'CHECKLIST_TEMPLATE':
        return PageUrls.organisationChecklistTemplates({
          organisationId: folder.organisationId,
          query: {
            folderId: folder.displayName !== 'ROOTS' && folder.id,
          },
        })
      case 'SITE_BOX':
        return PageUrls.projectSiteBox({
          organisationId: folder.organisationId,
          projectId: auditResult.project?.id,
          query: {
            folderId: folder.displayName !== 'ROOTS' && folder.id,
          },
        })
      default:
        return '#'
    }
  }

  return (
    <TimelineBlockBase title={localizedStrings.folderInformation}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Flex alignItems='flex-start' justifyContent='flex-start'>
          <FolderIcon color='disabled' sx={{ mr: 1 }} fontSize='small' />
          <Box>
            <Link href={getFolderUrl(momovedFromFolder)} target='_blank'>
              <Text variant='body2'>{momovedFromFolder.displayName}</Text>
            </Link>
            <Text fontSize={12}>{`${
              localizedStrings.createdBy
            }: ${momovedFromFolder.createdBy.getFullName()}`}</Text>
            <Text fontSize={12}>{`${localizedStrings.createdOn}:
            ${moment(momovedFromFolder.createdOn).format(
              'do MMM yyyy',
            )}`}</Text>
          </Box>
        </Flex>
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Flex alignItems='flex-start' justifyContent='flex-start'>
          <FolderIcon color='disabled' sx={{ mr: 1 }} fontSize='small' />
          <Box>
            <Link href={getFolderUrl(momovedFromFolder)} target='_blank'>
              <Text variant='body2'>{movedToFolder.displayName}</Text>
            </Link>
            <Text fontSize={12}>{`${
              localizedStrings.createdBy
            }: ${movedToFolder.createdBy.getFullName()}`}</Text>
            <Text fontSize={12}>{`${localizedStrings.createdOn}:
            ${moment(movedToFolder.createdOn).format('do MMM yyyy')}`}</Text>
          </Box>
        </Flex>
      </Flex>
    </TimelineBlockBase>
  )
}
