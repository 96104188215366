import { AuditResult } from '@core/entities/audit/AuditResult'
import { uuid } from '@modules/legacy-workflow-poc/utils'
import { IUser } from '@core/entities/user'

export const auditActions = [
  'SENT_TO_PERMIT_HOLDER',
  'PERMIT_RECEIVED',
  'PERMIT_SIGNED',
  'PERMIT_APPROVED',
  'PERMIT_REJECTED',
  'PERMIT_EXTENSION_REQUESTED',
  'PERMIT_EXTENSION_APPROVED',
  'PERMIT_EXTENSION_REJECTED',
  'PERMIT_OPENED',
  'PERMIT_EXPIRED',
  'PERMIT_DISCARDED',
  'PERMIT_POST_PERMIT_CHECK_COMPLETE',
  'PERMIT_SIGN_OFF_COMPLETE',
  'WORK_COMPLETE',
  'PERMIT_REQUESTED',
  'PERMIT_CANCELLED',
  'PERMIT_SUSPENDED',
  'PERMIT_SUSPENDED_RESUME',
  'PERMIT_SUSPENDED_RESUME_REQUESTED',
  'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED',
  'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED',
  'PERMIT_CHANGES_REQUESTED',
  'PERMIT_CHANGES_ADDRESSED',
  'PERMIT_PERIODIC_CHECKS_SUBMITTED',
  'PERMIT_TRANSFER_INITIATED',
  'PERMIT_TRANSFER_ACCEPTED',
  'PERMIT_TRANSFER_APPROVED',
  'PERMIT_TRANSFER_DECLINED',
  'PERMIT_TRANSFER_REJECTED',
  'PERMIT_TRANSFER_CANCELLED',
  'PERMIT_CHECKIN',
  'PERMIT_SHORTENED',
  'PERMIT_HOLDER_CHANGED',
  'PERMIT_DRAFT_CREATED',
  'CHECKLIST_DRAFT_CREATED',
  'PARTICIPANT_BRIEFED',
  'PARTICIPANT_REMOVED',
  'USER_ADDED_TO_ORGANISATION',
  'PROJECT_MEMBER_ADDED',
  'PROJECT_MEMBER_ACTIVATED',
  'PROJECT_MEMBER_DISABLED',
  'PROJECT_MEMBER_REMOVED',
  'PROJECT_MEMBER_ROLE_UPDATED',
  'PROJECT_STATUS_CHANGED',
  'PROJECT_EXTERNAL_CODE_CHANGED',
  'PROJECT_APPROVAL_ASSIGNMENTS_UPDATED',
  'PROJECT_PERMISSION_ASSIGNMENTS_UPDATED',
  'TEMPLATE_CREATED',
  'TEMPLATE_PUBLISHED',
  'TEMPLATE_UNPUBLISHED',
  'TEMPLATE_ARCHIVED',
  'TEMPLATE_UNARCHIVED',
  'TEMPLATE_UPDATED',
  'TEMPLATE_MOVED',
  'PERMIT_TEMPLATE_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED',
  'PROJECT_TEMPLATE_UPDATED',
  'PROJECT_TEMPLATE_ADDED',
  'PROJECT_TEMPLATE_REMOVED',
  'PROJECT_TEMPLATE_VERSION_UPDATED',
  'TEMPLATE_VERSION_CREATED',
  'TEMPLATE_VERSION_UPDATED',
  'TEMPLATE_VERSION_DELETED',
  'TEMPLATE_VERSION_ACTIVATED',
  'TEMPLATE_VERSION_MOVED_TO_DRAFT',
  'CHECKLIST_COMPLETED',
  'CHECKLIST_TEMPLATE_CREATED',
  'CHECKLIST_TEMPLATE_UPDATED',
  'CHECKLIST_TEMPLATE_ARCHIVED',
  'CHECKLIST_TEMPLATE_UNARCHIVED',
  'CHECKLIST_TEMPLATE_MOVED',
  'CHECKLIST_TEMPLATE_VERSION_CREATED',
  'CHECKLIST_TEMPLATE_VERSION_UPDATED',
  'CHECKLIST_TEMPLATE_VERSION_DISCARDED',
  'CHECKLIST_TEMPLATE_VERSION_ACTIVATED',
  'CHECKLIST_TEMPLATE_VERSION_MOVED_TO_DRAFT',
  'USER_ROLE_UPDATED_IN_ORGANISATION',
  'USER_REMOVED_FROM_ORGANISATION',
  'ORGANISATION_MEMBER_ACTIVATED',
  'ORGANISATION_MEMBER_DISABLED',
  'ORGANISATION_PROJECT_ADDED',
  'GUEST_TOKEN_GENERATED',
  'GUEST_TOKEN_REFRESHED',
  'GUEST_TOKEN_DELETED',
  'GUEST_TOKENS_ORG_DELETE',
  'GUEST_TOKENS_PROJ_DELETE',
  'USER_PROFILE_CHANGED',
  'DATA_DIRECTORY_TABLE_UPLOAD',
  'DATA_DIRECTORY_TABLE_MOVED',
  'DATA_DIRECTORY_TABLE_DELETE',
  'FOLDER_CREATED',
  'FOLDER_MOVED',
  'FOLDER_RENAMED',
  'USER_ADDED_TO_PROJECT_AUTH_GROUP',
  'USER_REMOVED_FROM_PROJECT_AUTH_GROUP',
  'USER_ADDED_TO_ORGANISATION_AUTH_GROUP',
  'USER_REMOVED_FROM_ORGANISATION_AUTH_GROUP',
  'TASK_CANCELLED',
  'PERMIT_CHECKLIST_DONE',
  'UNKNOWN',
  'CREATED',
  'UPDATED',
  'FORMULAS_RECALCULATED',
  'EMAIL_NOTIFICATION_SENT',
  'COMMENT_CREATED',
  'IMAGE_WARNINGS_CREATED',
]

export const mockContextOptions = [
  { label: 'Permit', value: 'PERMIT' },
  { label: 'Checklist', value: 'CHECKLIST' },
  { label: 'Project', value: 'PROJECT' },
  { label: 'Register', value: 'REGISTER' },
  { label: 'Permit Template', value: 'TEMPLATE' },
  { label: 'Template', value: 'TEMPLATE' },
  { label: 'Organisation', value: 'ORGANISATION' },
  { label: 'User', value: 'USER' },
]

const mockUserJohnDoe: IUser = {
  id: 1,
  email: 'test.user@siteassist.co',
  firstName: 'John',
  lastName: 'Doe',
  phone: '09876543210',
  company: 'Construction Co.',
  avatarUrl: '',
}

const mockUserJaneRoberts: IUser = {
  id: 2469275,
  firstName: 'Jane',
  lastName: 'Roberts',
  company: 'SA Construction Co',
  email: 'jane.roberts@saconstruction.co.uk',
  phone: '09876543210',
  avatarUrl: '',
}

export const mockAuditResultBase = ({
  action,
  platform = 'WEB',
  signature = false,
  permitId = null,
  checklist = false,
  register = false,
  registerItem = false,
  proof = false,
  user = true,
  projectUser = false,
  organisationUser = false,
  geoJSON = false,
  ...props
}) => {
  const { metadata, ...rest } = props
  return AuditResult.new({
    id: uuid(),
    createdOn: new Date().toISOString(),
    platform,
    action,
    signatureUrl: signature && '/images/mock-signature.png',
    organisation: {
      id: 14413,
      name: 'Eduardo Org',
    },
    project: {
      id: 5648,
      name: 'Building a new house',
    },
    permit: permitId && {
      id: permitId,
      shortUUID: '1234',
      templateName: 'Test Permit',
    },
    checklist: checklist && {
      id: 1234,
      shortUUID: '1234',
      templateName: 'Test Checklist',
    },
    register: register && {
      id: 12344,
      name: 'A Register Machine',
    },
    registerItem: registerItem && {
      id: '1233',
      uniqueId: 'WD120XVN',
    },
    user: user && mockUserJohnDoe,
    metadata: {
      mediaUrls: proof && ['/images/mock-proof.png'],
      mediaUrlsWithType: proof && [
        {
          getUrl: '/images/mock-proof.png',
          type: 'image/png',
        },
      ],
      ...metadata,
    },
    geoJSON: geoJSON && {
      type: 'FeatureCollection',
      features: [
        {
          'type': 'Feature',
          'properties': {
            'projectId': null,
            'referencedResourceId': 950339,
            'referencedResourceType': 'PERMIT',
            'inputType': 'UNKNOWN',
            'w3wLocation': null,
            'locationAccuracyInMeters': 10.343804843386815,
            'locationAccuracyStatus': 'GOOD',
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [-0.162278688322371, 51.54558803299213],
          },
        },
      ],
    },
    projectUser: projectUser && mockUserJaneRoberts,
    organisationUser: organisationUser && mockUserJaneRoberts,
    ...rest,
  })
}

export const mockResults = (permitId) => [
  // ORGANISATION_PERMISSION_ASSIGNMENTS_UPDATED
  // GUEST_TOKEN_GENERATED
  // GUEST_TOKEN_REFRESHED
  // GUEST_TOKEN_DELETED
  // GUEST_TOKENS_ORG_DELETE
  // GUEST_TOKENS_PROJ_DELETE
  // USER_PROFILE_CHANGED
  // USER_EMAIL_UPDATED
  // USER_ADDED_TO_PROJECT_AUTH_GROUP
  // USER_ADDED_TO_ORGANISATION_AUTH_GROUP
  // USER_REMOVED_FROM_PROJECT_AUTH_GROUP
  // USER_REMOVED_FROM_ORGANISATION_AUTH_GROUP
  // IMAGE_WARNINGS_CREATED
  // TASK_CANCELLED
  // AUTH_GROUP_NAME_UPDATED
  // AUTH_GROUP_DELETED
  // AUTH_GROUP_CREATED
  // EMAIL_NOTIFICATION_SENT
  // PERMIT_CHECKLIST_DONE
  // UNARCHIVED
  // ARCHIVED
  // UPDATED
  // CREATED
  // FORMULAS_RECALCULATED
  // REGISTER_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED
  mockAuditResultBase({
    action: 'COMMENT_CREATED',
    proof: true,
    metadata: {
      'commentSecondaryReferenceObjectIdText': '34ba-6494-84a4',
      'commentSecondaryReferenceObjectType': 'QUESTION',
      'commentAnsweredQuestionDataResult': {
        'id': '34ba-6494-84a4',
        'body': '<p>how many more times?</p>',
        'questionType': 'ALPHANUMERIC',
        'requiresProof': 'OPTIONAL',
        'invalid': false,
        'submissionText': 'ASD',
        'requiresSignature': 'OPTIONAL',
        'required': true,
        'isHidden': false,
      },
    },
  }),
  mockAuditResultBase({
    action: 'GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED',
    organisationUser: true,
    metadata: {
      permissionsUpdatedData: {
        'groupName': 'a top level Group',
        'oldPermissions': [
          {
            'type': 'MANAGE_AUTH_GROUP_MEMBERS',
            'enabled': false,
          },
        ],
        'newPermissions': [
          {
            'type': 'MANAGE_AUTH_GROUP_MEMBERS',
            'enabled': true,
          },
        ],
      },
    },
  }),
  mockAuditResultBase({
    action: 'ORGANISATION_PROJECT_ADDED',
    organisationUser: true,
    metadata: {
      preOrgMemberData: {
        roles: ['USER'],
      },
      postOrgMemberData: {
        roles: ['ACCOUNT_OWNER'],
      },
    },
  }),
  mockAuditResultBase({
    action: 'USER_ROLE_UPDATED_IN_ORGANISATION',
    organisationUser: true,
    metadata: {
      preOrgMemberData: {
        roles: ['USER'],
      },
      postOrgMemberData: {
        roles: ['ACCOUNT_OWNER'],
      },
    },
  }),
  mockAuditResultBase({
    action: 'ORGANISATION_MEMBER_ACTIVATED',
    organisationUser: true,
  }),
  mockAuditResultBase({
    action: 'ORGANISATION_MEMBER_DISABLED',
    organisationUser: true,
  }),
  // mockAuditResultBase({
  //   // note: there is no FE implementation to trigger this action
  //   action: 'USER_REMOVED_FROM_ORGANISATION',
  //   organisationUser: true,
  // }),
  mockAuditResultBase({
    action: 'USER_ADDED_TO_ORGANISATION',
    organisationUser: true,
  }),
  mockAuditResultBase({
    action: 'FOLDER_MOVED',
    info: 'Folder moved from oldFolder to newFolder',
  }),
  mockAuditResultBase({
    action: 'FOLDER_RENAMED',
    info: 'Folder renamed from oldFolder to newFolder',
  }),
  mockAuditResultBase({
    action: 'FOLDER_CREATED',
  }),
  mockAuditResultBase({
    action: 'SITE_BOX_DOCUMENT_VERSION_STATUS_CHANGED',
    siteboxDocument: {
      id: 7211,
      name: 'test docs (4).pdf',
    },
    siteboxDocumentVersion: {
      id: 7209,
      name: 'test docs (4).pdf',
      documentVersion: 1,
    },
  }),
  mockAuditResultBase({
    action: 'SITE_BOX_DOCUMENT_MOVED',
    info: 'Document moved from 12345 to 123456',
    siteboxDocument: {
      id: 7211,
      name: 'test docs (renamed).pdf',
    },
    metadata: {
      movedFromFolder: {
        'id': 1666215,
        'organisationId': 14413,
        'projectId': 5648,
        'displayName': 'ROOT',
        'createdBy': mockUserJohnDoe,
        'createdOn': '2024-03-07T14:36:30.620024Z',
        'modifiedOn': '2024-03-07T14:36:30.620024Z',
        'type': 'SITE_BOX',
      },
      movedToFolder: {
        'id': 1666216,
        'organisationId': 14413,
        'projectId': 5648,
        'parentId': 1666215,
        'displayName': 'test',
        'createdBy': mockUserJohnDoe,
        'createdOn': '2024-03-07T14:36:37.940963Z',
        'modifiedOn': '2024-03-07T14:36:37.940963Z',
        'type': 'SITE_BOX',
      },
    },
  }),
  mockAuditResultBase({
    action: 'SITE_BOX_DOCUMENT_STATUS_CHANGED',
    metadata: {
      preSiteBoxDocumentValuesData: {
        name: 'test docs (renamed).pdf',
        status: 'LIVE',
      },
      postSiteBoxDocumentValuesData: {
        name: 'test docs (renamed).pdf',
        status: 'ARCHIVED',
      },
    },
    siteboxDocument: {
      id: 7211,
      name: 'test docs (renamed).pdf',
    },
  }),
  mockAuditResultBase({
    action: 'SITE_BOX_DOCUMENT_UPDATED',
    metadata: {
      preSiteBoxDocumentValuesData: {
        name: 'test docs (4).pdf',
        status: 'LIVE',
      },
      postSiteBoxDocumentValuesData: {
        name: 'test docs (renamed).pdf',
        status: 'LIVE',
      },
    },
    siteboxDocument: {
      id: 7211,
      name: 'test docs (renamed).pdf',
    },
  }),
  mockAuditResultBase({
    action: 'SITE_BOX_DOCUMENT_VERSION_CREATED',
    siteboxDocument: {
      id: 7211,
      name: 'test docs (4).pdf',
    },
    siteboxDocumentVersion: {
      id: 7209,
      name: 'test docs (4).pdf',
      documentVersion: 1,
    },
  }),
  mockAuditResultBase({
    action: 'SITE_BOX_DOCUMENT_CREATED',
    siteboxDocument: {
      id: 7211,
      name: 'test docs (4).pdf',
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED',
    metadata: {
      permissionsUpdatedData: {
        groupName: 'a new group',
        groupId: 123,
        oldPermissions: [
          {
            type: 'CHECKLIST_INITIATION',
            enabled: true,
          },
        ],
        newPermissions: [
          {
            type: 'CHECKLIST_INITIATION',
            enabled: false,
          },
        ],
      },
    },
  }),
  mockAuditResultBase({
    action: 'PROJECT_CHECKLIST_TEMPLATE_VERSION_UPDATED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
    checklistTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    action: 'PROJECT_CHECKLIST_TEMPLATE_REMOVED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
  }),
  mockAuditResultBase({
    action: 'PROJECT_CHECKLIST_TEMPLATE_ADDED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_VERSION_DISCARDED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
    checklistTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_VERSION_MOVED_TO_DRAFT',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
    checklistTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_VERSION_ACTIVATED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
    checklistTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_VERSION_UPDATED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
    checklistTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_VERSION_CREATED',
    // checklistTemplate & checklistTemplateVersion are not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
    checklistTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_UPDATED',
    // checklistTemplate is not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_CREATED',
    // checklistTemplate is not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_ARCHIVED',
    // checklistTemplate is not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_TEMPLATE_UNARCHIVED',
    // checklistTemplate is not returned from the backend yet
    checklistTemplate: {
      id: 1,
      name: 'Test Checklist',
    },
  }),
  mockAuditResultBase({
    // note: backend should return the name of the checklist inside the checklist object so we can link it properly
    action: 'CHECKLIST_COMPLETED',
    signature: true,
    checklist: true,
  }),
  mockAuditResultBase({
    // last checked: There is no FE implementation to trigger this action
    action: 'TEMPLATE_VERSION_MOVED_TO_DRAFT',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
    permitTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate or version from backend
    action: 'TEMPLATE_VERSION_ACTIVATED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
    permitTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate or version from backend
    action: 'TEMPLATE_VERSION_DELETED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
    permitTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate or version from backend
    action: 'TEMPLATE_VERSION_UPDATED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
    permitTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate or version from backend
    action: 'TEMPLATE_VERSION_CREATED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
    permitTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate or version from backend
    action: 'PROJECT_TEMPLATE_VERSION_UPDATED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
    permitTemplateVersion: {
      id: 1,
      version: 1,
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate from backend
    action: 'PROJECT_TEMPLATE_REMOVED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning permitTemplate from backend
    action: 'PROJECT_TEMPLATE_ADDED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_TEMPLATE_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED',
    metadata: {
      permissionsUpdatedData: {
        'groupName': 'a new group',
        'oldPermissions': [
          {
            'type': 'PERMIT_DO_FINAL_SIGN_OFF',
            'enabled': false,
            'finalSignOffData': {
              'id': '669961',
              'label': 'Default Final Sign-Off',
            },
          },
          {
            'type': 'REVIEW_PERMIT_TRANSFER_REQUEST',
            'enabled': false,
          },
          {
            'type': 'PERMIT_APPROVE_SUSPENSION_RESUME_REQUEST',
            'enabled': false,
          },
          {
            'type': 'PERMIT_REVIEW_EXTENSION',
            'enabled': true,
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'PERMIT_INITIATION',
            'enabled': true,
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'PERMIT_APPROVE',
            'enabled': true,
            'approvalData': {
              'id': '669961',
              'label': 'Default Approval',
            },
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
        ],
        'newPermissions': [
          {
            'type': 'PERMIT_DO_FINAL_SIGN_OFF',
            'enabled': true,
            'finalSignOffData': {
              'id': '669961',
              'label': 'Default Final Sign-Off',
            },
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'REVIEW_PERMIT_TRANSFER_REQUEST',
            'enabled': true,
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'PERMIT_APPROVE_SUSPENSION_RESUME_REQUEST',
            'enabled': true,
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'PERMIT_REVIEW_EXTENSION',
            'enabled': true,
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'PERMIT_INITIATION',
            'enabled': true,
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
          {
            'type': 'PERMIT_APPROVE',
            'enabled': true,
            'approvalData': {
              'id': '669961',
              'label': 'Default Approval',
            },
            'metadataData': {
              'restrictedToProjectsData': [],
            },
          },
        ],
      },
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_MOVED',
    info: 'Template moved from oldFolder to newFolder',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_UPDATED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_UNARCHIVED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_ARCHIVED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_UNPUBLISHED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_PUBLISHED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    action: 'TEMPLATE_CREATED',
    permitTemplate: {
      id: 1,
      name: 'Test Template',
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning the correct metadata from backend
    action: 'PROJECT_FINAL_SIGN_OFF_ASSIGNMENTS_UPDATED',
    info: 'this mock data does not reflect the actual data',
  }),
  mockAuditResultBase({
    action: 'PROJECT_PERMISSION_ASSIGNMENTS_UPDATED',
    metadata: {
      permissionAssignmentReferenceObjectId: 907457,
      permissionAssignmentReferenceObjectName: 'new template test',
      permissionAssignmentReferenceObjectType: 'PERMIT_TEMPLATE',
      permissionAssignmentAuthGroupIds: [316415, 123],
      permissionAssignmentAuthGroupNames: ['a new group', 'Another group'],
      permissionAssignmentType: 'PERMIT_INITIATION',
    },
  }),
  mockAuditResultBase({
    // last checked: this action is not returning the correct metadata from backend
    action: 'PROJECT_APPROVAL_ASSIGNMENTS_UPDATED',
    info: 'this mock data does not reflect the actual data',
  }),
  mockAuditResultBase({
    action: 'PROJECT_EXTERNAL_CODE_CHANGED',
    info: 'External code changed from oldExternalCode to updatedExternalCode',
  }),
  mockAuditResultBase({
    // last time checked, this action was not implemented in the backend
    action: 'PROJECT_STATUS_CHANGED',
    metadata: {
      projectStatusAfter: 'ARCHIVED',
      projectStatusBefore: 'LIVE',
    },
  }),
  mockAuditResultBase({
    action: 'PROJECT_MEMBER_ROLE_UPDATED',
    projectUser: true,
    signature: true,
    metadata: {
      'preProjectMemberData': {
        'roles': ['AUTHORISED_PERSON'],
      },
      'postProjectMemberData': {
        'roles': ['PROJECT_VIEWER'],
      },
    },
  }),
  mockAuditResultBase({
    action: 'PROJECT_MEMBER_REMOVED',
    projectUser: true,
  }),
  mockAuditResultBase({
    action: 'PROJECT_MEMBER_DISABLED',
    projectUser: true,
  }),
  mockAuditResultBase({
    action: 'PROJECT_MEMBER_ACTIVATED',
    projectUser: true,
  }),
  mockAuditResultBase({
    action: 'PROJECT_MEMBER_ADDED',
    projectUser: true,
  }),
  mockAuditResultBase({
    action: 'PARTICIPANT_REMOVED',
    signature: true,
    info: 'James Brown (external) - removed by John Doe (Construction Co.)',
    appliedToUser: {
      firstName: 'James',
      lastName: 'Brown',
      company: 'Build & Construct .Co',
    },
    metadata: {
      permitRoleName: 'QASE Participant 3 ',
    },
  }),
  mockAuditResultBase({
    action: 'PARTICIPANT_BRIEFED',
    geoJSON: true,
    signature: true,
    proof: true,
    appliedToUser: {
      firstName: 'James',
      lastName: 'Brown',
      company: 'Build & Construct .Co',
    },
    info: 'James Brown (external) - signed via John Doe (Construction Co.)',
    metadata: {
      permitRoleName: 'QASE Participant 3 ',
    },
  }),
  mockAuditResultBase({
    action: 'CHECKLIST_DRAFT_CREATED',
  }),
  mockAuditResultBase({
    action: 'PERMIT_DRAFT_CREATED',
  }),
  mockAuditResultBase({
    action: 'PERMIT_PERIODIC_CHECKS_SUBMITTED',
    signature: true,
    permitId,
  }),
  mockAuditResultBase({
    action: 'PERMIT_HOLDER_CHANGED',
    permitId,
    signature: true,
    info: 'This permit was shortened for some reason',
    metadata: {
      changedPermitHolderFrom: mockUserJohnDoe,
      changedPermitHolderTo: mockUserJaneRoberts,
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_SHORTENED',
    permitId,
    signature: true,
    info: 'This permit was shortened for some reason',
    metadata: {
      permitShortenedEndsOn: '2022-10-01T00:00:00Z',
      permitPreviousEndsOn: '2022-10-31T00:00:00Z',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_CHECKIN',
    permitId,
    signature: true,
    geoJSON: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_TRANSFER_CANCELLED',
    permitId,
    signature: true,
    info: 'This permit transfer was cancelled for some reason',
    metadata: {
      transferReceiver: {
        id: 36340,
        email: 'testing.mark@test.com',
        firstName: 'Mark',
        lastName: 'Markson',
        phone: '123123123123',
        company: 'SiteAssist',
        role: 'USER',
        locale: 'en_GB',
        timezone: 'Europe/London',
        externalId: '123123123',
      },
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_TRANSFER_DECLINED',
    permitId,
    signature: true,
    info: 'This transfer was declined for some reason',
    metadata: {
      transferReceiver: {
        id: 36340,
        email: 'testing.mark@test.com',
        firstName: 'Mark',
        lastName: 'Markson',
        phone: '123123123123',
        company: 'SiteAssist',
        role: 'USER',
        locale: 'en_GB',
        timezone: 'Europe/London',
        externalId: '123123123',
      },
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_TRANSFER_REJECTED',
    permitId,
    signature: true,
    info: 'This permit transfer was rejected for some reason',
    metadata: {
      transferReceiver: {
        id: 36340,
        email: 'testing.mark@test.com',
        firstName: 'Mark',
        lastName: 'Markson',
        phone: '123123123123',
        company: 'SiteAssist',
        role: 'USER',
        locale: 'en_GB',
        timezone: 'Europe/London',
        externalId: '123123123',
      },
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_TRANSFER_APPROVED',
    permitId,
    signature: true,
    metadata: {
      transferReceiver: {
        id: 36340,
        email: 'testing.mark@test.com',
        firstName: 'Mark',
        lastName: 'Markson',
        phone: '123123123123',
        company: 'SiteAssist',
        role: 'USER',
        locale: 'en_GB',
        timezone: 'Europe/London',
        externalId: '123123123',
      },
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_TRANSFER_ACCEPTED',
    permitId,
    signature: true,
    metadata: {
      transferReceiver: {
        id: 36340,
        email: 'testing.mark@test.com',
        firstName: 'Mark',
        lastName: 'Markson',
        phone: '123123123123',
        company: 'SiteAssist',
        role: 'USER',
        locale: 'en_GB',
        timezone: 'Europe/London',
        externalId: '123123123',
      },
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_TRANSFER_INITIATED',
    permitId,
    info: 'This permit transfer was initiated for some reason',
    signature: true,
    metadata: {
      transferReceiver: {
        id: 36340,
        email: 'testing.mark@test.com',
        firstName: 'Mark',
        lastName: 'Markson',
        phone: '123123123123',
        company: 'SiteAssist',
        role: 'USER',
        locale: 'en_GB',
        timezone: 'Europe/London',
        externalId: '123123123',
      },
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED',
    permitId,
    info: 'This permit suspension resume request was declined for some reason',
    signature: true,
    metadata: {
      permitStatusAfter: 'SUSPENDED',
      permitStatusBefore: 'SUSPENDED_RESUME_REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED',
    permitId,
    signature: true,
    metadata: {
      permitStatusAfter: 'OPEN',
      permitStatusBefore: 'SUSPENDED_RESUME_REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_SUSPENDED_RESUME_REQUESTED',
    permitId,
    info: 'This permit needs to be resumed for some reason',
    proof: true,
    metadata: {
      permitStatusAfter: 'SUSPENDED_RESUME_REQUESTED',
      permitStatusBefore: 'SUSPENDED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_SUSPENDED_RESUME',
    permitId,
    info: 'This permit was resumed for some reason',
    signature: true,
    proof: true,
    metadata: {
      permitStatusAfter: 'OPEN',
      permitStatusBefore: 'SUSPENDED_RESUME_REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_SUSPENDED',
    permitId,
    info: 'This permit was suspended for some reason',
    signature: true,
    proof: true,
    metadata: {
      suspensionReasonCategory: 'GENERIC',
      permitStatusAfter: 'SUSPENDED',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'WORK_COMPLETE',
  }),
  mockAuditResultBase({
    action: 'PERMIT_SIGN_OFF_COMPLETE',
    permitId,
    signature: true,
    info: 'Show if user sign off with a comment',
  }),
  mockAuditResultBase({
    action: 'PERMIT_POST_PERMIT_CHECK_COMPLETE',
    permitId,
    signature: true,
    geoJSON: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_CANCELLED',
    permitId,
    info: 'This permit was cancelled for some reason',
    signature: true,
    metadata: {
      permitStatusAfter: 'CANCELLED',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_REJECTED',
    permitId,
    info: 'This permit was rejected for some reason',
    signature: true,
    metadata: {
      permitStatusAfter: 'REJECTED',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_DISCARDED',
    permitId,
    info: 'This permit was discarded for some reason',
    metadata: {
      permitStatusAfter: 'DISCARDED',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_EXPIRED',
    permitId,
    user: false,
    metadata: {
      permitStatusAfter: 'REQUESTED',
      permitStatusBefore: 'EXPIRED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_EXTENSION_REJECTED',
    permitId,
    metadata: {
      permitExtensionEndsOn: '2022-10-01T09:00:00Z',
      permitPreviousEndsOn: '2022-09-01T09:00:00Z',
      permitStatusAfter: 'OPEN',
      permitStatusBefore: 'EXTENSION_REQUESTED',
    },
    info: 'This extension was rejected for some reason',
    signature: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_EXTENSION_APPROVED',
    permitId,
    metadata: {
      permitExtensionEndsOn: '2022-10-01T09:00:00Z',
      permitPreviousEndsOn: '2022-09-01T09:00:00Z',
      permitStatusAfter: 'OPEN',
      permitStatusBefore: 'EXTENSION_REQUESTED',
    },
    signature: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_EXTENSION_REQUESTED',
    permitId,
    metadata: {
      permitExtensionEndsOn: '2022-10-01T09:00:00Z',
      permitPreviousEndsOn: '2022-09-01T09:00:00Z',
      permitStatusAfter: 'EXTENSION_REQUESTED',
      permitStatusBefore: 'OPEN',
    },
    info: 'This permit needs to be extended for some reason',
    signature: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_SIGNED',
    permitId,
    metadata: {
      permitStatusAfter: 'SIGNED',
      permitStatusBefore: 'UNSIGNED',
    },
    info: 'FirstName LastName (company name) - signed via AnotherFirstName AnotherLastName',
    signature: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_OPENED',
    permitId,
    platform: 'UNKNOWN',
    user: false,
    metadata: {
      permitStatusAfter: 'OPEN',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_APPROVED',
    permitId,
    signature: true,
    metadata: {
      permitStatusAfter: 'OPEN',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_CHANGES_ADDRESSED',
    permitId,
    signature: true,
  }),
  mockAuditResultBase({
    action: 'PERMIT_CHANGES_REQUESTED',
    permitId,
    signature: true,
    info: 'Some changes need to be made.',
  }),
  mockAuditResultBase({
    action: 'PERMIT_RECEIVED',
    permitId,
    signature: true,
    metadata: {
      permitStatusAfter: 'SENT_TO_PERMIT_HOLDER',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'SENT_TO_PERMIT_HOLDER',
    permitId,
    platform: 'UNKNOWN',
    user: false,
    metadata: {
      permitStatusAfter: 'SENT_TO_PERMIT_HOLDER',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_APPROVED',
    permitId,
    signature: true,
    metadata: {
      permitStatusAfter: 'SENT_TO_PERMIT_HOLDER',
      permitStatusBefore: 'REQUESTED',
    },
  }),
  mockAuditResultBase({
    action: 'IMAGE_WARNINGS_CREATED',
    permitId,
    platform: 'UNKNOWN',
    user: false,
    metadata: {
      warnings: [
        {
          'id': 42345,
          'type': 'IMAGE_DUPLICATE',
          'questionId': '9c2d-2dcd-ae27',
          'details': {
            'duplicates': [
              {
                'mediaKey': '6449-proof/7bf08e26-a169-480f-a3d8-fa7f5300e47f',
                'questionId': '9c2d-2dcd-ae27',
                'downloadUrl': '/images/mock-proof.png',
                'duplicatedIn': [
                  {
                    'id': 826163,
                    'type': 'PERMIT',
                    'uuid': '308678DE',
                    'summary': 'test warning cloned 2',
                    'mediaKey':
                      '6449-proof/f1cb6f4c-60ee-44a7-aa1e-6ba6c81dba09',
                    'uploadOn': [2024, 7, 16, 10, 37, 31, 35003000],
                    'questionId': '9396-bd1f-394d',
                    'uploadedBy': 'Account Owner',
                    'templateName': 'use another hammer',
                    'uploadedData': [
                      {
                        'uploadOn': [2024, 7, 16, 10, 37, 31, 35003000],
                        'uploadedBy': 'Account Owner',
                      },
                      {
                        'uploadOn': [2024, 7, 16, 10, 37, 30, 773922000],
                        'uploadedBy': 'Account Owner',
                      },
                    ],
                    'templateVersionId': 825259,
                  },
                ],
              },
            ],
          },
          'downloadUrls': ['/images/mock-proof.png'],
        },
      ],
    },
  }),
  mockAuditResultBase({
    action: 'PERMIT_REQUESTED',
    permitId,
    signature: true,
  }),
]
