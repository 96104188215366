import React from 'react'
import { Text } from '@fundamentals'
import { TimelineBlockBase } from '@common/Timeline'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { localizedStrings } from '@core/strings'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineBriefedParticipantBlock: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  return (
    <TimelineBlockBase title={localizedStrings.briefedParticipant}>
      <Text variant='body2'>
        {`${auditResult.appliedToUser.getFullName()}
        ${
          !auditResult.appliedToUser.id ? ` (${localizedStrings.external})` : ''
        }`}
      </Text>
      <Text sx={{ fontSize: 12 }} color='text.secondary'>
        {auditResult.appliedToUser.company}
      </Text>
      <Text sx={{ fontSize: 12 }} color='text.secondary'>
        {auditResult.metadata.permitRoleName ||
          auditResult.metadata.checklistRoleName}
      </Text>
    </TimelineBlockBase>
  )
}
