import { Box, Text, Flex } from '@fundamentals'
import React from 'react'
import { Profile } from 'webapp/components/common/Profile'
import { NavbarProps } from './types'
import { IconButton } from '@common/material'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { NotificationsNoneOutlined } from '@mui/icons-material'
import { useDrawer } from '@common/GlobalDrawer'
import { NotificationsList } from '@modules/notifications'
import { useGetNotificationsUnseenCounter } from '@core/react-query/features/notifications'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import useConstants from '@core/providers/useConstants'

const Navbar: React.FC<NavbarProps> = ({ children, variant, ...props }) => {
  const { data: profile } = useGetProfile()
  const { showDrawer } = useDrawer()
  const { flags } = useFeatureFlags(['web_notifications'])
  const { data: unseenCounterData } = useGetNotificationsUnseenCounter(
    { userId: profile?.id },
    { enabled: !!profile && !!flags.web_notifications },
  )
  const { constants } = useConstants()

  const bgcolor = variant === 'dashboard' ? '#1976D2' : '#FAFAFA'
  const unseenNotificationsCount = unseenCounterData?.unseen ?? 0
  const unseenNotificationsLabel =
    unseenNotificationsCount > 99 ? '99+' : unseenNotificationsCount

  return (
    <Box
      position='sticky'
      zIndex={10}
      top={0}
      bgcolor={variant === 'dashboard' && '#1976D2'}
      {...props}
    >
      <Flex
        alignItems='center'
        height={constants.nav_bar_height}
        bgcolor={bgcolor}
        ml={3}
      >
        {children}
        <Box flexGrow={1} />
        {flags.web_notifications && (
          <IconButton
            onClick={() => showDrawer(<NotificationsList pageSize={25} />)}
          >
            <Flex>
              <NotificationsNoneOutlined
                sx={{
                  color: variant === 'dashboard' ? 'white' : 'inherit',
                }}
              />
              {!!unseenNotificationsCount && (
                <Box
                  position='absolute'
                  top={3}
                  right={18}
                  bgcolor='error.main'
                  borderRadius={99}
                  pt='1px'
                  px='6px'
                >
                  <Text fontSize={12} color='white' numberOfLines={1}>
                    {unseenNotificationsLabel}
                  </Text>
                </Box>
              )}
            </Flex>
          </IconButton>
        )}
        <Profile variant={variant} />
      </Flex>
    </Box>
  )
}

export { Navbar }
