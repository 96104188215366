import React, { useEffect } from 'react'
import { Flex } from '@fundamentals'
import { useIntercom } from 'react-use-intercom'
import { PageLayoutProps, TopBarProps } from './types'
import { useScrolling, useUserRedirect } from '@common/hooks'
import { NextRouter } from 'next/router'
import { Navbar } from '@common/Navbar'
import { NavigationAwareSidebar } from 'webapp/components/elements/NavigationAwareSidebar'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { NavigationAwareBreadcrumbs } from '@elements'

const TopBar: React.FC<TopBarProps> = ({ variant }) => {
  return (
    <Navbar variant={variant}>
      <NavigationAwareBreadcrumbs variant={variant} />
    </Navbar>
  )
}

const PageLayout: React.FC<PageLayoutProps> = ({
  page,
  router,
  fullscreen,
  hideSecondarySidebar,
  hideSidebar,
  variant = 'default',
  pageSx,
  withoutBottomPadding,
  ...props
}) => {
  const { data: profile } = useGetProfile()
  const { boot } = useIntercom()
  const { scrollingElementId } = useScrolling()
  const { isReady } = useUserRedirect()

  const isFullscreen = (router: NextRouter): boolean => {
    return router.query?.fullScreen === 'true'
  }

  useEffect(() => {
    if (!Constants.E2E && profile) {
      boot({
        email: profile.email,
        userId: profile.id.toString(),
        name: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phone,
        companies: profile.organisations?.map((o) => {
          return { companyId: o.id.toString(), name: o.name, role: o.role }
        }),
      })
    }
  }, [boot, profile])

  if (!isReady) {
    return null
  }

  if (isFullscreen(router) || fullscreen) {
    return (
      <Flex
        minHeight='100vh'
        height='100vh'
        width='100%'
        bgcolor='#FAFAFA'
        flexDirection={variant === 'dashboard' ? 'column' : 'row'}
        {...props}
      >
        {variant === 'dashboard' && <TopBar variant={variant} />}
        {page}
      </Flex>
    )
  } else {
    return (
      <Flex
        minHeight='100vh'
        height='100vh'
        width='100%'
        bgcolor='#FAFAFA'
        {...props}
      >
        {!hideSidebar && (
          <NavigationAwareSidebar hideSecondarySidebar={hideSecondarySidebar} />
        )}
        <Flex
          id={scrollingElementId}
          flexDirection='column'
          flex={5}
          sx={{
            pb: withoutBottomPadding ? 0 : 8.5,
            overflow: 'auto',
            ...pageSx,
          }}
        >
          <TopBar variant={variant} />
          <Flex
            flexDirection='column'
            flex={1}
            sx={{
              pb: withoutBottomPadding ? 0 : 3,
              px: variant === 'dashboard' ? 0 : 3,
            }}
          >
            {page}
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export { PageLayout }
